import React, { useEffect, useState } from 'react';
import Footer from './footer';
import {  getAllProduct } from '../Api';
import { actionType } from '../Context/reducer';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useStateValue } from '../Context/StateProvider';

export const DashboardUserCard = ({ data, index }) => {
  const createdAt = moment(new Date(data.createdAt)).format('MMM Do YY');

  return (
    <motion.div
      key={data._id}
      className="relative w-full rounded-md flex justify-evenly items-center px-2 py-4 bg-lightOverlay  cursor-pointer  hover:bg-card hover:shadow-md"
    >
      <p className="text-base  text-center text-black"> {data?.name}</p>
      <p className="text-base  text-center text-black">{data.invest_price}</p>
      <p className="text-base  text-center text-black">{data.user_id}</p>
      <p className="text-base  text-center text-black">{createdAt}</p>
    </motion.div>
  );
};

const DashProduct = () => {
  const [{ allProduct }, dispatch] = useStateValue();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (!allProduct) {
      getAllProduct().then((data) => {
        dispatch({
          type: actionType.SET_ALL_PRODUCT,
          allProduct: data?.product,
        });
      });
    }
  }, [allProduct,dispatch]);

  // Filter products based on the search query
  const filteredProducts = allProduct?.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="w-full p-4 flex  flex-col">
      <div className="flex justify-center mb-3 w-full ">
        <label className="block text-xl mx-2 my-1">Search: </label>
        <input
          className="flex-1 p-2 border mr-1  border-gray-300 rounded-lg"
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search name......."
        />

      
      </div>
      <div className="relative w-full py-12 min-h[400px] overflow-x-scroll my-4 flex flex-col items-center justify-start p-4 border-gray-300 rounded-md gap-3">
        <div className="absolute w-full top-4 left-4">
          <p className="text-sm text-black font-sm">
            count :{' '}
            <span className="text-sm font-sm text-textColor">
              {filteredProducts?.length || 0}
            </span>
          </p>
        </div>
        {filteredProducts &&
          filteredProducts.map((data, i) => (
            <DashboardUserCard data={data} index={i} key={data._id} />
          ))}
      </div>
       
    </div>
  );
};

export default DashProduct;
