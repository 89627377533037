import React, { useState, useContext} from 'react';
import Footer from './footer';
import { useStateValue } from '../Context/StateProvider';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import { getError } from '../utils';
function PaymentForm() {
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');
  const [selectedBank, setSelectedBank] = useState(''); // New state for selected bank
  const navigate = useNavigate();

  const [{ allOrder, totalIncome, referredIncome,investedIncome,desposit }] = useStateValue();
  const { state } = useContext(Store);
  const { userInfo } = state;

  const bankDetails = desposit ? desposit.filter((obj) => obj?.user_id === userInfo?._id) : []; 
  const handleSubmit = async (e) => {
    const filteredData = allOrder ? allOrder.filter((obj) => obj?.user_id === userInfo?._id) : [];
    const selectedBankDetails = bankDetails.find((bank) => bank.bank_name === selectedBank);
    const paidData = filteredData.filter(item => item.paid === true); 
    if (!amount ) {
      toast.warning('Required fields are missing');
    } else {
      e.preventDefault();
      const isAmountValid = totalIncome >= 2000 && parseFloat(amount) >= 2000;
      if (!isAmountValid || amount > totalIncome ) {
        toast.warning('You need to have a minimum balance of 2000, and the amount must be at least 2000.');
        return;
      }else if ( paidData.length === 0   ){
        toast.warning('You need to purchase at least 3000 machine');
        return;
      }  

    try {
      await axios.post(`https://optimistic-investment.onrender.com/api/payment/save`, {
        bank_name: selectedBankDetails.bank_name,
        account_name: selectedBankDetails.account_name,
        account_number: selectedBankDetails.account_number,
        amount: amount,
        balance: totalIncome,
        user_id: userInfo._id,
        user_name: userInfo.name,
      });
      toast.success(' Successfull Sent!');
      window.location.reload();
      navigate("/home");             
    } catch (error) {
      const errorMessage = getError(error);
      toast.error(errorMessage);
    }
  }};
 
  return (
    <div>
     <div className="flex justify-center py-3 px-5 my-4 bg-white rounded mx-4">
        <h1 className="flex items-center justify-center text-xl">Withdrawal</h1>      
    </div>
      <div className="flex flex-col w-full mb-20 h-screen bg-gray-100">
        {/* <h2 className="text-2xl text-center font-bold mb-4">Payment</h2> */}
        <div className="w-full h-full bg-white rounded-lg shadow-md p-6">
        <div className="flex flex-col w-full mt-5 mb-20 h-screen bg-gray-100">
        <h2 className="text-2xl text-center font-bold mb-4">Enter  Account </h2>
        <form className="mx-3" onSubmit={handleSubmit}>  
        <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Select Bank <span className="text-red-600">*</span>
        </label>
        <select
          className="w-full p-2 border border-gray-300 rounded-lg"
          value={selectedBank}
          onChange={(e) => setSelectedBank(e.target.value)}
        >
        <option>Select Bank</option>
          {bankDetails.map((bank, index) => (
            <option key={index} value={bank.bank_name}>
            <div className='flex  justify-around'>
               <p>{bank.bank_name} </p>
              <p>{bank.account_number}</p>
            </div>
             
            </option>
          ))}
        </select>
      </div>    
          <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Amount <span className="text-red-600">*</span></label>
              <input
                className="w-full p-2 border border-gray-300 rounded-lg"
                type="number" 
                required               
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <span>Min amount 2000.00</span>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Additional Note: <span className='text-gr text-gray-400'>(optional)</span></label>
              <textarea
                className="w-full p-2 border border-gray-300 rounded-lg"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder='Note'
              />
              
            </div>
          <button type="submit" className="text-white  bg-red-500 w-190 m-2 p-2 rounded-xl">
            Withdraw
          </button>
          <p className='italic text-center font-mono text-black'> <span>Note: </span > Minimum withdrawal is 2000NGN and 7% tax.</p>
        </form>      
         
      </div>
        </div>
         
      </div>
    </div>
  );
}

export default PaymentForm;



