import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import logo from '../Asset/logo.jpg'
import { Store } from '../Store';
import { toast } from 'react-toastify';
import Footer from './footer';
import { useStateValue } from '../Context/StateProvider';
const Referral = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ allUser, allOrder, investedIncome,referredIncome }, dispatch] = useStateValue();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get(`https://optimistic-investment.onrender.com/api/user/me/${userInfo._id}`)
      .then((response) => {
        setUsers(response.data);        
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setUsers,userInfo]);

  const handleCopyReferralCode = () => {
    const referralLink = userInfo.referralLink
    ;
    navigator.clipboard.writeText(referralLink);
    toast.success('Referral link copied to clipboard!');
  };

   const referredPeople = allUser ? allUser.filter((obj) => obj?.code === userInfo?.referralCode) : [];   
const paidReferredOrders = allOrder ? allOrder.filter((order) => referredPeople.some((person) => person?._id === order?.user_id && order?.paid)) : [];
  
  const processedUserIds = new Set(); 
  let referredIncom = 0;
paidReferredOrders.forEach((order) => {
  // Check if the user_id has been processed already
  if (!processedUserIds.has(order.user_id)) {
    // Calculate income from each unique user (25% of invest_price) and add it to referredIncome
    referredIncom += (order?.invest_price * 0.35) || 0; // Assuming invest_price is the property representing the order amount
    processedUserIds.add(order.user_id); // Mark the user_id as processed
    // console.log( processedUserIds.add(order.user_id))
  }
});
  
  return (
    // <div className="relative backdrop-blur-2xl">
       <div className="h-screen pt-20 bg-textColor relative ">     
      <div className='h-[25vh]  bg-blend-saturation-2xl'>
     
        {users && (
          <div className=' flex h-full justify-end flex-col shadow-lg rounded-2xl shadow-textolor'>            
                   <div className='flex justify-center mt-11 items-center'>
                          <img src={logo} className=' h-[90px] border-2 border-textColor rounded-3xl' alt="" />   
                    </div>      
     
         
            
            <div className='flex gap-2 mb-4 mx-7 items-end'>
              <h1 className='text-2xl italic text-white'>Referral Link:</h1>
              <p className='text-xl\ italic text-white'>https://www.copperamet.../</p>
            </div>
            <motion.button
              whileTap={{ scale: 0.8 }}
              transition={{ duration: 0.3 }}
              type='button'
              className='bg-blue-800 mb-3 mx-5 text-white w-[100px] p-1 m-2 rounded-xl'
              onClick={handleCopyReferralCode}
            >
              Copy link
            </motion.button>
          </div>
        )}
      </div>   
      <div className="flex  mt-12 text-blue flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border border-blue-800 rounded-lg">
              <table className="min-w-full divide-y  divide-blue-800">          
                <tbody className="divide-y p-20 divide-blue-800">                                                  
                  <tr className=''>
                    <td className="px-6 py-4 text-sm font-medium whitespace-nowrap p-10 text-blue-800 ">
                      Total member:
                    </td>                   
                    <td className="px-6 py-4 text-sm text-blue-800 ">
                    {referredPeople?.length}
                    </td>                                    
                  </tr>
                  <tr className=''>
                    <td className="px-6 py-4 text-sm font-medium whitespace-nowrap  p-10 text-blue-800">
                      Total Referred Income:
                    </td>                   
                    <td className="px-6 py-4 text-sm  text-blue-800 ">
                    ₦ {referredIncome}
                    </td>                                    
                    </tr>                  
                </tbody>
              </table>
            </div>
            {/* <div className="g mt-4 border border- rounded-lg"> */}
            <p className='italic mt-5 text-center font-mono text-blue-800'> <span>Note: </span >Invite friend with your Referral link to invest and get 25% return bonus.</p>
            {/* </div>                            */}
          </div>
        </div>
      </div>
       
    {/* </div> */}
    </div>
   
  );
};

export default Referral;




