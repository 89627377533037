import React, { useContext} from 'react';
import { Store } from '../Store';
import { useStateValue } from '../Context/StateProvider';


const Header = () => {
  const { state} = useContext(Store);
  const { userInfo } = state;
  const [{user, totalIncome }] = useStateValue();
  

  return (
    <div className="flex justify-between px-5 my-4 bg-white rounded mx-4">
      {!userInfo ? (
        <h1 className="flex items-center justify-center  terxt-4xl">Hi, LOGIN</h1>
      ) : (
        <h1 className="flex items-center justify-center terxt-4xl">Hi, {userInfo?.number}</h1>
      )}
      <div className='flex flex-col justify-center items-center'>
        <h1>Account Balance : </h1>
        <p>₦ {  totalIncome }</p>
      </div>
    </div>
  );
};

export default Header;
