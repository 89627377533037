import axios from "axios";

export const baseURL = "https://optimistic-investment.onrender.com/";


export const Signin = async (  number,password) => {
  try {
    const res = await axios.post(`${baseURL}api/user/signin`,{
      number,
      password
    })
    return res.data;

  } catch (error) {
    return null;
  }

};
export const getMine = async ( user_id) => {
  try {
    const res = await axios.post(`${baseURL}api/order/getMine`,{
      user_id
    })
    return res.data;

  } catch (error) {
    return null;
  }

};

export const Signup = async ( name,email,password,codeParam,referralCode ) => {
  try {
    const res = await axios.post(`${baseURL}api/user/signup`, {
        name,
        email,
        password,
        codeParam,
        referralCode
    });
    return res.data;
  } catch (error) {
    return null;
  }
};
export const SaveOrder = async ( name,invest_price,imageURL,description,user_id,daily_amt,total_income,contract_cycle) => {
  try {
    const res = await axios.post(`${baseURL}api/order/save`,{
      name,
      invest_price,
      imageURL,
      description,
      user_id,
      daily_amt,
      total_income,
      contract_cycle
    }  
  );
    return res.data;

  } catch (error) {
    return null;
  }
};
export const getOneProduct= async (id) => {
  try {
    const res = axios.get(`${baseURL}api/order/getOne/${id}`);
    return res;
  } catch (error) {
    return null;
  }
};
export const getUserOrder= async (user_id) => {
  try {
    const res = axios.get(`${baseURL}api/order/getMine`,{
      user_id
    });
    return res;
  } catch (error) {
    return null;
  }
};
export const getAllPayRequest = async () => {
    try {
      const res = await axios.get(`${baseURL}api/payment/getAll`);      
      return res.data;
  
    } catch (error) {
      return null;
    }
  };
export const getAllProduct = async () => {
    try {
      const res = await axios.get(`${baseURL}api/product/getAll`);      
      return res.data;
  
    } catch (error) {
      return null;
    }
  };
export const getAllUser = async () => {
    try {
      const res = await axios.get(`${baseURL}api/user/getUser`);
     
      return res.data;
  
    } catch (error) {
      return null;
    }
  };
export const getAllOrder = async () => {
    try {
      const res = await axios.get(`${baseURL}api/order/getAll`);
     
      return res.data;
  
    } catch (error) {
      return null;
    }
  };
  export const changeUserRole = async (userId, role) => {
    try {
      const res = axios.put(`${baseURL}api/user/updateRole/${userId}`, {
        data: { role: role },
      });
      return res;
    } catch (error) {
      return null;
    }
  };