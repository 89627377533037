import React, { useContext, useEffect, useState } from 'react';
import logo from '../Asset/logo.jpg'
import image  from '../Asset/background.jpg'
import { toast } from 'react-toastify';
import { getError } from '../utils'
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Store } from '../Store';
import "./login.css"
import { TypeAnimation } from 'react-type-animation';
import axios from 'axios';

const SignUp = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl ? redirectInUrl : '/';
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const { state } = useContext(Store);
  const { userInfo } = state;

    useEffect(() => {
    const getRefFromURL = () => {
      const urlParams = new URLSearchParams(search);
      const refParam = urlParams.get('ref');
      if (refParam) {
        setReferralCode(refParam);
      }
    };

    getRefFromURL();
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, search, userInfo]);
  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    try {      
      const res = await axios.post(`https://optimistic-investment.onrender.com/api/user/signup`, {
        name,
        number,
        password,
        code:referralCode ||'' ,        
    });
      console.log(res);
      if (res.error) {      
        toast.error("number is already available");
      } else {
        toast.success('User Registered Successfully');
        localStorage.setItem('userInfo', JSON.stringify(res.data));
        navigate('/home');
        window.location.reload();
      }
    } catch (err) {
      toast.error(getError(err));
    }
  };  

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  return (
    <div className="body bg-gray-200 text-black min-h-screen">
     < div className='relative flex h-full w-full flex-col '>3
      <div className="flex flex-col  w-full pt-8   h-full  items-center">
      <div className='flex justify-center mt-11 items-center'>
                          <img src={logo} className=' h-[100px] border-2 border-textColor rounded-3xl' alt="" />   
                    </div>     
        <h2 className="text-2xl text-center font-serif  text-black italic font-bold mb-4">Coppera Metals</h2>
        <TypeAnimation
      sequence={[        
        'We are Coppera  ',
        1000, 
        'We are Coppera  ',
        1000, 
        'We are Coppera Metals ',
        1000,     
        'We are Coppera Metals create  ',
        1000,     
        'We are Coppera Metals create an ',
        1000,     
        'We are Coppera Metals create an  account',
        1000,     
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: "15px",textAlign:"center" , fontFamily: "italic", display: 'inline-block' }}
      repeat={Infinity}
    />
        <form className=" bg-transparent mb-10 w-[80%] border-[3px] bg-white border-ble-200  rounded-lg shadow-md mt-11 p-6" onSubmit={submitHandler}>
          {referralCode &&
            <div className='mb-4'>
              <label className="block text-black items-center justify-center text-sm font-bold mb-2">Referral Code<span className='t text-red-600'>*</span></label>
              <input
                className="w-full p-2 border bg-transparent capitalize  border-gray-300 rounded-lg"
                type="text" value={referralCode}
                readOnly
                name=""
                placeholder='Name' id="" />
            </div>}
          <div className='mb-4'>
            <label className="block text-black items-center justify-center text-sm font-bold mb-2">Name <span className='t text-red-600'>*</span></label>
            <input
              className="w-full p-2 border border-gray-300 rounded-lg"
              type="text"
              onChange={(e) => setName(e.target.value)}
              required
              name="" placeholder='Name' id="" />
          </div>
          <div className='mb-4'>
            <label className="block text-black items-center justify-center text-sm font-bold mb-2">Number <span className='t text-red-600'>*</span></label>
            <input
              className="w-full p-2 border border-gray-300 rounded-lg"
              type="number"
              required
              onChange={(e) => setNumber(e.target.value)}
              name="" placeholder='Number' id="" />
          </div>
          <div className='mb-4'>
            <label className="block text-black items-center justify-center text-sm font-bold mb-2">Password <span className='t text-red-600'>*</span></label>
            <input
              className="w-full p-2 border border-gray-300 rounded-lg"
              type="password"
              required
              placeholder='Password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className='mb-4'>
            <label className="block text-black items-center justify-center text-sm font-bold mb-2"> Comfirm Password <span className='t text-red-600'>*</span></label>
            <input
              className="w-full p-2 border border-gray-300 rounded-lg"
              type="password"
              placeholder='Confirm Password'
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button className="bg-blue-900 mt-5 text-white font-bold py-2 px-4 rounded" type="submit" >Register</button>
          <div className='flex w-full  text-black ml-1 text-sm  gap-1'>
            <p className="block text-slate-300 items-center justify-center text-sm font-bold mb-2">I dont have an account?{' '}</p>
            <Link to={"/login"}> <p className="text-black text-sm font-bold  rounded">Login</p></Link>
          </div>
          <div className='flex items-center w-full flex-col justify-center'>
            <i className="block text-black text-sm font-bold ">©copyright 2023  </i>
            <i className="block text-black text-sm font-bold ">All Right Reserved</i>
          </div>
        </form>
      </div>
    </div>
    </div>
    
  )
}

export default SignUp;
