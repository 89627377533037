export const actionType = {
  SET_USER: "SET_USER",
  SET_PRODUCT:"SET_PRODUCT",
  SET_ALL_USERS:"SET_ALL_USERS",
  SET_ALL_PRODUCT:"SET_ALL_PRODUCT",
  SET_ORDER:"SET_ORDER",
  SET_ALL_ORDER:"SET_ALL_ORDER",
  SET_TOTAL_INCOME:"SET_TOTAL_INCOME",
  SET_ALL_PAY:"SET_ALL_PAY",
  SET_ALL_REFERRED_INCOME:"SET_ALL_REFERRED_INCOME",
  SET_ALL_PAYS:"SET_ALL_PAYS",
  SET_ACCBAL:"SET_ACCBAL",
  SET_PAY:"SET_PAY",
  SET_DESPOSIT:"SET_DESPOSIT",
  SET_ALL_PAYMENT:"SET_ALL_PAYMENT",
  SET_ALL_DESP_PAYMENT:"SET_ALL_DESP_PAYMENT",
  SET_ALL_DESPOSIT:"SET_ALL_DESPOSIT",
  SET_PAYMENT:"SET_PAYMENT",
  SET_DESP_PAYMENT:"SET_DESP_PAYMENT",
  SET_INVESTED_INCOME:"SET_INVESTED_INCOME"
};

const reducer = (state, action) => {
  switch (action.type) {
        case actionType.SET_USER:
      return {
        ...state,
        user: action.user,
      };
        case actionType.SET_DESPOSIT:
      return {
        ...state,
        desposit: action.desposit,
      };
        case actionType.SET_PRODUCT:
      return {
        ...state,
        product: action.product,
      };

        case actionType.SET_PAY:
      return {
        ...state,
        allPay: action.allPay,
      };
        case actionType.SET_ACCBAL:
      return {
        ...state,
        accBal: action.accBal,
      };
        case actionType.SET_ALL_PAY:
      return {
        ...state,
        pay: action.pay,
      };
        case actionType.SET_ALL_DESPOSIT:
      return {
        ...state,
        allDesposit: action.allDesposit,
      };
        case actionType.SET_ALL_REFERRED_INCOME:
      return {
        ...state,
        referredIncome: action.referredIncome,
      };
        case actionType.SET_ALL_PAYS:
      return {
        ...state,
        pays: action.pays,
      };
        case actionType.SET_PAYMENT:
      return {
        ...state,
        allPayment: action.allPayment,
      };
        case actionType.SET_ALL_PAYMENT:
      return {
        ...state,
        payment: action.payment,
      };
        case actionType.SET_ALL_DESP_PAYMENT:
      return {
        ...state,
        allDespPayment: action.allDespPayment,
      };
        case actionType.SET_DESP_PAYMENT:
      return {
        ...state,
        DespPayment: action.DespPayment,
      };
        case actionType.SET_TOTAL_INCOME:
      return {
        ...state,
        totalIncome: action.totalIncome,
      };
        case actionType.SET_INVESTED_INCOME:
      return {
        ...state,
        investedIncome: action.investedIncome
      };
        case actionType.SET_ORDER:
      return {
        ...state,
        order: action.order,
      };
        case actionType.SET_ALL_PRODUCT:
      return {
        ...state,
        allProduct: action.allProduct,
      };
        case actionType.SET_ALL_ORDER:
      return {
        ...state,
        allOrder: action.allOrder,
      };
                    
        case actionType.SET_ALL_USERS:
      return {
        ...state,
        allUser: action.allUser,
      };
                    
    default:
      return state;
  }
};

export default reducer;
