/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { motion } from 'framer-motion';
import { getError } from '../utils';
import { Store } from '../Store';
import { useNavigate, Link } from 'react-router-dom';
import { useStateValue } from '../Context/StateProvider';
const HistoryW = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ allOrder,allDespPayment,allPay,desposit}, dispatch] = useStateValue();
  const filteredData = allOrder? allOrder.filter((obj) => obj.user_id === userInfo._id).reverse() : [];
  const pays = allPay? allPay?.filter((obj) => obj.user_id === userInfo._id) : [];
  const deposit= desposit? desposit?.filter((obj) => obj.user_id === userInfo._id) : [];
  const despData = allDespPayment? allDespPayment.filter((obj) => obj?.user_id === userInfo?._id) : [];

  return (
    <div className="relative min-h-screen  h-full  backdrop-blur-2xl">
      <div className="h-full mb-11">
              <div className="flex justify-center py-3 px-5 my-4 bg-white rounded mx-4">
          <h1 className="flex items-center justify-center  text-2xl">History</h1>      
      </div>
        <div className="h-full mb-24">        
          <UserHistory desp={filteredData}  />

        </div>       
      </div>
    </div>  
  )
}

export const UserHistory = ({ data,desp  }) => {
  const navigate = useNavigate();
  const [orderData,setOrderData] = useState()
  
  const { state } = useContext(Store);
  const { userInfo } = state;
  // Combine all the data arrays into one and sort them by createdAt
  const allData = [ ...desp].reverse();
  allData.sort((a, b) => {
    // Convert createdAt strings to Date objects for comparison
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    // Compare the dates to sort in ascending order
    return dateA - dateB;
  });
 const m = allData.reverse()
  return (
    <>
      {m && m.length > 0 ? (
        m.map((orderItem) => (
          <div key={orderItem._id}>
            {orderItem.user_id === userInfo._id && (
              <motion.div
                initial={{ opacity: 0, translateX: -50 }}
                animate={{ opacity: 1, translateX: 0 }}
                className='relative m-2 w-[95vw] min-w-210 p-2 cursor-pointer bg-white shadow-md rounded-lg flex items-center'
              >            
                <div className='text-base w-full px-2 text-black'>
                  <div>
                     <h1>Payment</h1>
                  </div>
                <div className='flex w-full'>
  <h2 className={`block text-sm ${(orderItem.canceled ? "bg-red-600" : orderItem.paid || orderItem.isPaid ? "bg-green-600" : "bg-yellow-600")} p-1 rounded font-bold m-2`}>
    {orderItem.canceled ? "Canceled" : orderItem.paid || orderItem.isPaid ? "Successfull" : "Pending"}
  </h2>
</div>

                  <div className='flex flex-col '>
                    <div className='flex justify-between'>
                      <h3 className='text-sm'>Payment:</h3>
                      <h3 className={`text-sm  ${(orderItem.invest_price? "text-red-600" :  "text-green-600" )}`}>{orderItem?.invest_price? `-${orderItem.invest_price}` :  `+${orderItem.amount}`}NGN</h3>
                    </div>                
                    <div className='flex justify-between'>
                      <h3 className='text-sm'>Date:</h3>
                      <h3 className='text-sm text-textColor'>
                        {moment(new Date(orderItem.createdAt)).format('l')}
                      </h3>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </div>
        ))
      ) : (
        <div className='flex rounded-lg m-4 flex-col bg-white h-[200px] mt-10 items-center justify-center'>
          <p className='text-xl font-semibold'>No History</p>
         
        </div>
      )}

     

    </>
  );
};
export default HistoryW
