import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStateValue } from '../Context/StateProvider';
import moment from 'moment';
import { motion } from 'framer-motion';
import axios from 'axios';
import Footer from './footer';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import { actionType } from '../Context/reducer';
import { getError } from '../utils';





const UserDetails = (data) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);
  const [{ user, allPay, referredIncome,allDespPayment, desposit, allOrder, investedIncome, allUser }, dispatch] = useStateValue();
 
  const { state} = useContext(Store);
  const { userInfo } = state;  
    useEffect(() => {
      axios.get(`https://optimistic-investment.onrender.com/api/user/me/${id}`).then((res) => {        
        dispatch({
          type: actionType.SET_USER,
          user: res.data,
        });        
      });
      
    }, [dispatch,id]);
    
const filteredDat = allPay ? allPay?.filter((obj) => obj?.user_id === user?._id) : [];    
const paid = filteredDat.filter(item => item.isPaid === true);
const totalAmount = filteredDat.reduce((total, obj) => total + (obj.amount || 0), 0);

const referredPeople = allUser ? allUser.filter((obj) => obj?.code === user?.referralCode) : [];
const filteredData = allOrder ? allOrder.filter((obj) => obj?.user_id === user?._id) : [];
const despData = allDespPayment? allDespPayment.filter((obj) => obj?.user_id === user?._id) : [];
const paidDespData = despData.filter(item => item.paid === true);    
const paidReferredOrders = allOrder ? allOrder.filter((order) => referredPeople.some((person) => person?._id === order?.user_id && order?.paid)) : [];
const userBalance= user?.balance
let totalIncome = 0;
let totalOrder =0
const currTime = moment(new Date()).format('l');        
const paidData = filteredData.filter(item => item.paid === true);
const totalDesposit =  despData.reduce((total, obj) => total + (obj.amount || 0), 0);

for (let key in paidData) {
  const orderTime = moment(new Date(paidData[key].updatedAt)).format('l');
  const timeDifference = moment(currTime).diff(orderTime, 'days');
  const dailyAmt = paidData[key].daily_amt;
  const total = timeDifference * dailyAmt;
  totalIncome += total;      
}
for (let key in filteredData) {      
  totalOrder+=filteredData[key].invest_price;
}
const processedUserIds = new Set(); 
    let referredInco = 0;
    
    paidReferredOrders.forEach((order) => {  
      if (!processedUserIds.has(order._id)) {
       
        referredInco += (order?.invest_price * 0.25) || 0;
        processedUserIds.add(order._id); 
      }
    });
// const totalBalance = totalIncome + referredInco + userBalance + 500  - totalAmount ;
const totalBalance = investedIncome + referredInco + totalDesposit + userBalance + 300- totalAmount ;


      const rBalance =totalBalance - totalOrder
    console.log(totalBalance)
    console.log(rBalance);

const removeBalance = async (e) => {
  try {
    e.preventDefault()
    const currentBalance = user?.balance || 0; // Get the current balance
    const updatedBalance = currentBalance - parseFloat(amount); // Calculate the updated balance

    const { data } = await axios.put(`https://optimistic-investment.onrender.com/api/user/${id}`, {
      balance: updatedBalance,
    }, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    
    dispatch({
      type: actionType.SET_USER,
      user: data,
    });
    navigate("/me")      
   
  } catch (error) {
    toast.error(error);
  }
};
const updateBalance = async (e) => {
  try {
    e.preventDefault()
    const currentBalance = user?.balance || 0; // Get the current balance
    const updatedBalance = currentBalance + parseFloat(amount); // Calculate the updated balance

    const { data } = await axios.put(`https://optimistic-investment.onrender.com/api/user/${id}`, {
      balance: updatedBalance,
    }, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    
    dispatch({
      type: actionType.SET_USER,
      user: data,
    });
    navigate("/me")      
   
  } catch (error) {
    toast.error(error);
  }
};



    


  return (
    <div className='flex h-full flex-col mb-20 overflow-auto'>  
        {user&&
             <motion.div
              key={user?._id}      
              initial={{ opacity:1, translateX: -50 }}
              animate={{ opacity: 1, translateX: 0 }}
              transition={{ duration: 0.3 }}
              className="relative  w-full min-w-210 flex-col p-2 cursor-pointer tra  bg-textColor shadow-md rounded-lg flex items-center" 
            > 
                 <div className="w-30 min-w-[120px] h-[200px]  rounded-lg drop-shadow-lg relative overflow-hidden ">
                <motion.img
                  whileHover={{ scale: 1.05 }}
                  src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIAIsAiwMBIgACEQEDEQH/xAAbAAEAAgMBAQAAAAAAAAAAAAAABAUDBgcCAf/EADgQAAICAQIEAwUECQUAAAAAAAABAgMEBRESITFBBlFxEzJhkdEiQlKhFTNigYKDscHhByNDU3L/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/xAAXEQEBAQEAAAAAAAAAAAAAAAAAAREh/9oADAMBAAIRAxEAPwDuIAAAAAAAAAAAAAAAAAAAAAAAAAAAAADxbbCqLlN7IrcjMss5Q+xD4dRBPtyKqvemt/Jc2R56jBe7XJ/v2K7d/MFxNTv0jL/rXzPsdR/FV8mQAXDVtXm0zezbi/iiQmmk0918ChMlV9lT+xLZeT6Ew1dgjY2VC77LXDPy8ySRQAAAAAAAA8XWRqg5yeyR7KnPu9pZwL3YP5ssGK+6d8+KXTsvIxgFQAAQBDt1TApnwW5lEZeTsRJqsrurVlU4zg+koyTX5AewAAT2e65MtMPJ9quCbXGvzRVn2EnCanHqugsXV8DHRYra1OPcyGVAAAAAGHKs9lRKXfovUpn67ljqkvsQj5vcrixAAFQNF8Sa/ZmX2YuJY4YsHwtxezsa6v0Np8Q5EsXRcu2HKfBwxfk5ct/zOalDZLoiVp+fk6dercSzgf3o/dl6oigYjp+lahVqeFDJp5cXKUN+cZLqiYaZ4EyJLKysb7koKxLyae39zcyKAACdplm0p1t9eaLEpcSXDkVv47F0iVYAAigAArtU9+v0ZBJ+qL9XL1RANRAABFZ4mqduhZijzcYcW3o0zm51mcI2RcJpOMk00+6ZzjW9Iu0rJkpJyx5S/wBu3s15P4lFaAe6arL7Y1UwlOyT2jGK3bA2PwJU5ahk29oVKO/xb/wzdSr8PaX+i8Dgm077HxWNdE+y/d9S0IAAA9VfrYf+kXpR0LivrX7SLxEqwABFAABFz4ceO2usXuVRfSSaafRlLfX7K2UOy6ehYlYwB8W9kupUDxdKpVy9u4Kt9ePbZ+u5q2teK+GUqNL4Xtyd7XL+Ff3fyNVyci7KsdmTdO2b7zk2BvFsPC7m3Y8Di+E/oyx056bGO2nSxefalrd+pzELk91yfmijrYOf6V4lzsFxhdN5NHeNkt5L0f13N307Ox9Rxo34s+KPRrvF+TIJIAAladW5X8XaCLUjYNXsqVuvtS5skmWgAAAAAI2bj+2rW3vx936EkAUHR7Plsah4x1iTm9Nx5bJJe3ku/wCz9ToOoYzsqnZRFe2UXwp9JPsmcb1CjKx822GdXOvIcm5qa5tvuanWajAA0gAABN0jUrdLzI31c4vlZD8cSECDrFF1eRTC6mXFXYuKLXdE7Bx+Oasl7qfL4sovAmBmLTOHOqlVSpcVMZcpOL68uy3/AKm4RSSSS22M2tPoAIoAAAAAAAAQdT0nC1Sr2WdRG1Lo3ycfRrmicAOe6p/p9ZGTnpmXGUe1d62a/iX0NdyvDOtYz2s066S/FUuNfkdj2GxdTHD3p+cns8LJX8mX0M1Gi6re9qdOypfyml82drPmw0xy/A8C6rkNPKlViQ78UuOXyX1Nw0bwlpuluNnA8jIX/Lbz29F0RsGyPo0kfEtj6ARQAAAAAAAAAAAAAAAAAAAAAAAAAAAAB//Z"
                  alt="user image"
                  className="w-full h-full bg-white rounded-lg object-cover"
                />
          </div>
            <div className="text-base w-full px-2 text-black">    
                <div className='flex justify-center w-full '>
                  <h2 className="block text-xl font-medium   my-1">{user?._id}</h2>
                  </div>  
                  <div className='flex text-xl font-medium flex-col '>
                    <div className='flex justify-between'>
                       <h3 className=' text-xl font-medium '>Name:</h3>
                       <h3 className=' text-xl font-medium  '>{user?.name}</h3>
                    </div>
                    <div className='flex justify-between'>
                       <h3 className=' text-xl font-medium '>Number:</h3>
                       <h3 className=' text-xl font-medium  text-black'>{user?.number}</h3>
                    </div>
                    <div className='flex justify-between'>
                       <h3 className='  text-xl font-medium '>Referral People:</h3>
                       <h3 className=' text-xl font-medium  text-black'>{referredPeople.length}</h3>
                    </div>
                    <div className='flex justify-between'>
                       <h3 className='  text-xl font-medium '>Referral Income:</h3>
                       <h3 className=' text-xl font-medium  text-black'>{referredInco}</h3>
                    </div>
                    <div className='flex justify-between'>
                       <h3 className='  text-xl font-medium '>Referral Code :</h3>
                       <h3 className=' text-xl font-medium  text-black'>{user?.referralCode}</h3>
                    </div>                                 
                    <div className='flex justify-between'>
                       <h3 className=' text-xl font-medium '>User Total balance:</h3>
                       <h3 className=' text-xl font-medium  text-black'> {rBalance}</h3>
                    </div>                      
                  </div> 
                                                      
            </div>      
           </motion.div>   
        }
    
                     <div className="relative min-h-screen backdrop-blur-2xl">
    <div  className="flex flex-col justify-center items-center h-screen">    
                   <div className="flex flex-col w-full mb-20 h-full bg-gray-100">        
        <div className="w-full h-full bg-white rounded-lg shadow-md p-6">                                     
        <div className="flex flex-col w-full mt-5 mb-20 h-full bg-gray-100">
        <h2 className="text-2xl text-center font-bold mb-4">Add funds</h2>      
        <form className="mx-3" onSubmit={updateBalance}>
        
          <div className="flex flex-col gap-2 mt-2">
            <label className="block text-gray-700 text-sm font-bold mb-2"htmlFor="Amount">Add Funds <span className='t text-red-600'>*</span></label>
            <input 
            className="w-full p-2 border border-gray-300 rounded-lg"  
              type="text"
              id="Amount"
              name="Amount"              
              placeholder='Amount'
              onChange={(e) => setAmount(e.target.value )}
              required
            />
          </div>                  
          <button type="submit" className= "text-white bg-blue-900 w-190 m-2 p-2 rounded-xl">
                Add Funds
          </button>         
                        
          <button type="submit" onClick={removeBalance} className= "text-white bg-blue-900 w-190 m-2 p-2 rounded-xl">
                Remove Funds
          </button>         
        </form>      
        
      </div>
        </div>
      
      </div>
      {/* <div>
        <h3>Delete user</h3>
        <button onClick={handleDelete}>Delete user</button>
      </div> */}
    </div>
    </div>
       
    </div>
  );
};

export default UserDetails;
