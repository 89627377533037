import React, { useEffect,useState } from 'react'
import Footer from './footer'
import homeImage from '../Asset/g.jpg'
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from '../Context/StateProvider';
import { getAllProduct } from '../Api';
import { actionType } from '../Context/reducer';
import { GiAerialSignal } from 'react-icons/gi';

const Home = () => {
  const [{ allProduct}, dispatch] = useStateValue();
  useEffect(() => {
    if (!allProduct) {
      getAllProduct().then((data) => {
        dispatch({
          type: actionType.SET_ALL_PRODUCT,
          allProduct: data?.product,
        });        
      });
    }
  
  }, [allProduct,dispatch]);
  return (
    <div className="relative min-h-screen backdrop-blur-2xl">      
          <div className='w-full h-full bg-[url()] flex flex-col justify-between'>
      <div className='w-full h-full '>
          <div>     
            <div className='mb-9'>        
              <Product  data={allProduct}/>
            </div>
          </div>
      </div>  
      <Footer  /> 
    </div>
    </div>

  )
}

export default Home

export const Product= ({data})=>{
  const navigate = useNavigate();
  const [shuffledData, setShuffledData] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // Shuffle the data array when it changes
    if (data) {
      const shuffled = [...data];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      setShuffledData(shuffled);
    }
  }, [data]);

  useEffect(() => {
    // Change the image every 10 seconds
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === shuffledData.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000);

    return () => clearInterval(interval);
  }, [shuffledData]);
 return(
  <div className='flex px-2 pb-2 flex-col h mb-16 '>
        <div className='w-full'>
 <div className="flex flex-col justify-center w-full items-center   bg- rounded-2xl h-[130px] ">           
            <div className="w-full min-w-[90%] h-30 min-h-[100px] rounded-lg drop-shadow-lg relative  ">
        <img src={homeImage} className='h-full  mb-2 rounded-sm w-full' alt="" />   
          </div>
      </div>
        <h1 className='p-3  bg-white text-textColor break-all rounded-b-xl text-cent font-sanif  my-1'>
          Coppera metals is one of the China's largest  copper producers. We manufactures,distributes coppera products,and produces copper cathodeand other metals products. 
          Invest in our metals products and earn a daily income sustainable for revenue service.
        </h1>
        </div>
      
    <div className='mx-1 justify-center mt-2 items-center flex rounded bg-white'>
        <GiAerialSignal className=' text-textColor text-xl h-10  mx-2 w-10'/>
        <marquee behavior="move" direction="left"  className="text-sm  text-black"><i>803****9403 bought ₦15000   819****9803 bought ₦30000  903****9903 bought ₦15000 803****9403 bought ₦15000   819****9803 bought ₦30000 803****9403 bought ₦15000   819****9803 bought ₦30000  903****9903 bought ₦15000   913****8403 bought ₦2100  803****9733 bought ₦350000  903****9903 bought ₦15000   913****8403 bought ₦2100  803****9733 bought ₦350000  913****8403 bought ₦2100  803****9733 bought ₦350000</i></marquee>
    </div>
  {data?.map((data, index) => (        
        <motion.div
        key={data._id}      
        initial={{ opacity: 0, translateX: -50 }}
        animate={{ opacity: 1, translateX: 0 }}
        transition={{ duration: 0.3, delay: index * 0.1 }}
        className="relative my-2 w-[97vw] min-w-210 p-2 cursor-pointer   bg-white shadow-md rounded-lg flex items-center" 
      >
      
        <div className='flex  justify-between w-full'>
              <div className=''>
                  <h1 className='text-center font-semibold'> {data?.name}</h1>
                  <div className='flex text-sm font-medium flex-col '>
              <div className='flex '>
                 <h3 className=' text-sm font-normal'>Daily Income: <span className=' text-red-500'>₦ { data.daily_amt}</span></h3>               
              </div>
              <div className='flex '>
                 <h3 className=' text-sm font-normal'>Total Income: <span className=' text-red-500'>₦ { data.total_income}</span> </h3>                 
              </div>
              <div className='flex '>
                 <h3 className='  text-sm font-normal'>Validity: 45 Days</h3>                
              </div>
                
            </div> 
              </div>
              <div className=' '>
                 <h1 className='text-green-900'>₦ {data.invest_price}</h1>
                 <img src={data.imageURL} className='h-20 border  border-green-900 my-2 rounded-sm w-20' alt="" />
                 <div className='flex justify-center'>
                  <motion.button 
                  whileTap={{ scale: 0.8 }}        
                  transition={{ duration: 0.3}}
                  type='button' 
                  className=' bg-green-900  text-white w-20  p-1 rounded-xl' 
                  onClick={() => navigate(`/buynow/${data._id}`)}
              >
              Rental     
              </motion.button>  
              </div> 
              </div>
        </div>
    </motion.div>
    
  ))}
</div> 
 )
}