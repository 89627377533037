import { createContext, useReducer } from 'react'

export const Store = createContext();

const initialState = {
    userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : 'hello',


    cart: {
      //   shippingAddress: localStorage.getItem('shippingAddress')
      // ? JSON.parse(localStorage.getItem('shippingAddress'))
      // : { location: {} },
          order: localStorage.getItem('paymentMethod')
      ? localStorage.getItem('paymentMethod')
      : '',
        orderItems: [],
    },
};
function reducer(state, action) {
    switch(action.type) {
        case 'CART_ADD_ITEM':
            // add to cart
            const newItem = action.payload;
            const existItem = state.cart.orderItems.find(
                (item) => item._id === newItem._id
            )
            const orderItems = existItem
            ? state.cart.orderItems.map((items) => 
            items._id === existItem._id ? newItem : items
            )
            : [...state.cart.orderItems, newItem];
            return {...state, cart: {...state.cart, orderItems} }
            case 'CART_REMOVE_ITEM': {
                const orderItems = state.cart.orderItems.filter(
                    (item) => item._id !== action.payload._id
                )
            return {...state, cart: { ...state.cart, orderItems } };
            }
            case 'CART_CLEAR':
              return { ...state, cart: { ...state.cart, orderItems: [] } };
            case 'USER_SIGNIN':
                return { ...state, userInfo: action.payload };
                case 'USER_SIGNOUT':
                    return {
                      ...state,
                      userInfo: null,
                      cart: {
                        orderItems: [],
                        shippingAddress: {},
                        paymentMethod: '',
                      },
                    }
                    case 'SAVE_SHIPPING_ADDRESS':
                        return {
                          ...state,
                          cart: {
                            ...state.cart,
                            shippingAddress: action.payload,
                          },
                        };
                        case 'SAVE_PAYMENT_METHOD':
                            return {
                              ...state,
                              cart: { ...state.cart, paymentMethod: action.payload },
                            };

            default:
                return state;
    }
}

export function StoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};
    return <Store.Provider value={value}>{props.children}</Store.Provider>
}