import React, { useContext } from 'react'
import logo from '../Asset/logo.jpg'
import Footer from './footer'
import { Link, useNavigate } from 'react-router-dom'
import { BsBank, } from 'react-icons/bs'
import { RxDashboard } from 'react-icons/rx'
import { FaMoneyCheck,FaRobot } from 'react-icons/fa'
import { FcAbout } from 'react-icons/fc'
import {CiViewList} from 'react-icons/ci'
import {AiOutlineLink,AiOutlineHistory} from 'react-icons/ai'
import { BiLogIn ,BiMoneyWithdraw} from 'react-icons/bi'
import { Store } from '../Store'
import { toast } from 'react-toastify'
import { useStateValue } from '../Context/StateProvider'

const Me = () => {
  const {state, dispatch: ctxDispatch} = useContext(Store);
  const {userInfo} = state;
  const navigate = useNavigate();
  const  [{user,investedIncome, totalIncome,referredIncome}] = useStateValue()

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
     localStorage.removeItem('userInfo');   
    toast.success('User Logged Out')
    window.location.href = '/';
    window.localStorage.setItem("auth", "false");
  };
  const withdraw=()=>{   
   navigate("/payment") 
  }
  return (
    <div className="relative min-h-screen  backdrop-blur-2xl">
    <div className='w-full pb-40 h-full'>
      {!userInfo?(
        <div className=' mb-28'>
             <div className='flex flex-col justify-center items-center p-20'>
             <div className='flex justify-center mt-11 items-center'>
                          <img src={logo} className=' h-[90px] border-2 border-textColor rounded-3xl' alt="" />   
                    </div>      
               <h1 className='text-4xl text-black  text-center'>Coppera Metals </h1>You need Login or Register
             </div>
                          <div    className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                          <BsBank className='text-2xl '/>
                        <h1 className='text-sm text-center font-semibold'>My Bank Details</h1>
                    </div>    
                    <div    className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                          <FaMoneyCheck className='text-2xl '/>
                        <h1 className='text-sm text-center font-semibold'>Referral Income</h1>
`                    </div>
`                    <div    className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                          <BiMoneyWithdraw className='text-2xl '/>
                        <h1 className='text-sm text-center font-semibold'>Payment Record</h1>
                    </div>
                    <div    className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                        <FcAbout className='text-2xl '/>
                        <h1 className='text-sm text-center font-semibold'><Link to={'/about'}> About Us</Link></h1>
                    </div>
                    <div    className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                        <FaRobot className='text-2xl '/>
                        <h1 className='text-sm text-center font-semibold'>Official Channel</h1>
                    </div>
                    <div    className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                        <AiOutlineLink className='text-2xl '/>
                        <h1 className='text-sm text-center font-semibold'>Referral link</h1>
                    </div>
                    <div    className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                      <BiLogIn className='text-sm'/>
                    <h1 className='text-sm text-center font-semibold'> <Link to={'/'}>Login</Link></h1>
                  </div>   
                     
        </div>
      ) :(
       <div className=" min-h-screen ">
              <div className='gap-4 shadow-lg  shadow-blue-00 text-cyan-700  bg-gradient-to-l from-textColor to-blue-800 rounded-b-3xl px-4 py-10 w-full flex flex-col'>
                <div className='flex  items-center p-2 w-full mb-10 justify-evenly'>
                <div className='flex justify-center mt-11 items-center'>
                          <img src={logo} className=' h-[90px] border-2 border-textColor rounded-3xl' alt="" />   
                    </div>     
          <div className='flex flex-col justify-around'>            
              <h1 className='text-2xl text-white  italic  font-mono mt-5 font-bold uppercase  text-center'>Coppera Metals</h1> 
              <div className='flex flex-col items-center justify-around'>
              <h1 className=' text-white p-1 font-bold capitalize min-w-[80px] text-center rounded-md bg-textColor text-lg'>username :</h1>
              <h1 className= 'italic text-white text-lg'>{userInfo.number}</h1>
                </div>             
          </div>
                        
                </div>                                     
                <div className='flex flex-wrap justify-around w-full'>                  
                                                           
                        <h1 className='text-sm italic flex flex-col items-center text-white'>Total Income: <span className='text-white'>₦{totalIncome}</span> </h1>                
                        {/* // <h1 className='text-sm italic flex flex-col items-center text-white'>Balance: <span className='text-white'>₦{user? user?.balance : 0}</span> </h1>                 */}
                        <h1 className='text-sm italic flex flex-col items-center text-white'>Product Income: <span className='text-white'>₦{investedIncome}</span> </h1>                
                        <h1 className='text-sm italic flex flex-col items-center text-white'>Referral Income: <span className='text-white'>₦{referredIncome}</span> </h1>                
                </div>
                </div>              
  
       <div className='flex flex-col w-full h-full  mt-8 justify-center items-center px-4 '>
        
                <div className='flex flex-wrap justify-evenly w-full'>
                        {/* <div   className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                        <BsBank className='text-2xl '/>
                    <h1 className='text-sm text-center font-semibold'><Link to={'/paydesp'}>Recharge</Link></h1>
                  </div> */}
                        <div   className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                        <AiOutlineHistory className='text-2xl '/>
                    <h1 className='text-sm text-center font-semibold'><Link to={'/history'}>Payment History</Link></h1>
                  </div>
                  <div    className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                      <AiOutlineLink className='text-2xl '/>
                      <h1 className='text-sm text-center font-semibold'><Link to={'/referral'}>Referral link</Link></h1>
                  </div>
                  <div    className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                      <AiOutlineHistory className='text-2xl '/>
                        <h1 className='text-sm text-center font-semibold'>
                        <Link to={'/withdhistory'}>Withdrawal history</Link>
                       </h1>
                    </div>
                       <div onClick={withdraw}  className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                        <BiMoneyWithdraw className='text-2xl '/>
                      <h1 className='text-sm text-center font-semibold'>Withdraw</h1>
                  </div>
                       <div  className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                        <CiViewList className='text-2xl '/>
                      <h1 className='text-sm text-center font-semibold'><Link to={'/order'}>Product </Link></h1>
                  </div>
             <div     className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                        <BsBank className='text-2xl '/>
                      <h1 className='text-sm text-center font-semibold'><Link to={'/dashdeposit' }>Bind Account</Link></h1>
                  </div>   
                  <div onClick={signoutHandler}   className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                      <BiLogIn className='text-2xl'/>
                  <h1 className='text-sm text-center font-semibold'>LogOut</h1>
                  </div>     
                
                <div    className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                        <BsBank className='text-2xl '/>
                      <h1 className='text-sm text-center font-semibold'>App</h1>
                  </div>
                  {userInfo && userInfo.isAdmin && (
                    <div    className='flex flex-col  h-[100px] w-[100px] justify-center  p-3 gap-3 rounded items-center  bg-white mt-4'>
                      <RxDashboard className='text-2xl '/>
                      <h1 className='text-sm text-center font-semibold'><Link to={'/dashboard/dashuser'}>Admin Dashboard</Link></h1>
                    </div>
                  )}      
                 </div>
                </div>     
       </div>
                          
      )

      }
        
    </div>   
    </div>
  )
}

export default Me
