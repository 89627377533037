import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PaystackButton } from 'react-paystack';
import axios from 'axios';
import { Store } from '../Store';
import { actionType } from '../Context/reducer';
import { useStateValue } from '../Context/StateProvider';
import { getError } from '../utils';

const PayDeposit = () => {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;  
  const [amount, setAmount] = useState('');
  const [{ user, totalIncome, desposit },dispatch] = useStateValue();

// paystack api ingrating 
const config = {
  reference: new Date().getTime().toString(),
  email: 'coppera@gmail.com',
  amount: amount*100,
  publicKey: 'pk_live_3fe0a28bb6f8e9394e8d559baaecfe5fc4d00aad',
};

const handlePaystackSuccessAction = (reference) => {
  axios.post(`https://optimistic-investment.onrender.com/api/desposit/save`, { 
    name:userInfo.name,
    number:userInfo.number,      
    amount:amount,
    balance: user.balance,
    user_id: userInfo?._id,          
})    
  console.log('Payment success');  
  // handledesposit();
  navigate("/me")
  console.log(reference);
};

const handlePaystackCloseAction = () => {
  console.log('Payment dialog closed');
};
const componentProps = {
  ...config,    
  text: 'Checkout',
  onSuccess: handlePaystackSuccessAction,
  onClose: handlePaystackCloseAction,
};

  return (
    <div className="relative min-h-screen bg-white h-full  backdrop-blur-2xl">
      <div className="flex flex-col w-full  justify-center items-center h-full">
        
        <div className="flex flex-col  w-full pb-5 h-full bg-gray-100">
          <div className="flex justify-center h-full m  px-5 w-full py-4 bg-white rounded mx-4">
          <h1 className="flex items-center justify-center text-black text-2xl">Recharge</h1>
        </div>
          <div className="w-full pb-20 min-h-screen bg-white rounded-lg shadow-md p-6">
            <p className='text-center text-lg font-serif'><span className='t text-red-600'>*</span> Use your internet/mobile Banking platform from your bank to pay into this account</p>
            <div className="flex flex-col w-full mt-5 pb-5 p-4 bg-gray-100">
              
                <div className="flex flex-col gap-2 mt-2">
                  <label htmlFor="amount" className="block text-gray-700 text-sm font-bold pb-2">Amount<span className='t text-red-600'>*</span></label>
                  <input
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    type="number" 
                    required               
                    placeholder="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
              
                  />
                </div>
                <button type="submit" className="text-white bg-blue-900 w-190 m-2 p-2 rounded-xl">
                <PaystackButton {...componentProps} />                     
                </button>
              
            </div>
            <h1 className='text-xl font-serif underline pb-1'>Note<span className='t text-red-600'>*</span></h1>
            <p className=' pb-4 font-serif text-lg'>1.  This account is valid for one time please do not transfer multiple times.</p>
            <p className=' pb-4 font-serif text-lg'>2.  It takes 5-10 minutes after payment is been made before approved. </p>
            <p className=' pb-4 font-serif text-lg'>3.  Do not repeat transfer to the previous receivering account. </p>
             <p className=' pb-48 font-serif text-lg'>4.  Always get new account number on every payment by click the recharge menu.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayDeposit;
