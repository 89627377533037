import React, { useContext, useState } from 'react';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useStateValue } from '../Context/StateProvider';
import Footer from './footer';

function Profile() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const [{ user }, dispatch] = useStateValue();
  const { userInfo } = state;
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  console.log(user)
  const submitHandler = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("Passwords don't match");
      return;
    }
    try {
      const { data } = await axios.put(
        'https://optimistic-investment.onrender.com/api/user/profile/password',
        { currentPassword, newPassword },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: 'UPDATE_SUCCESS',
      });
      ctxDispatch({ type: 'USER_SIGNIN', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      toast.success('Password updated successfully');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      dispatch({
        type: 'FETCH_FAIL',
      });
      toast.error(err.response.data.message);
    }
  };

  return (
    <div className='flex flex-col justify-center items-center h-[100vh]   w-full'>
      <div className='flex w-full flex-col gap-4 justify-center items-center'>
        <h2 className='text-4xl text-black'>Change Password</h2>
        <form className='flex flex-col  gap-5 mx-4 justify-center items-center w-[90%]' onSubmit={submitHandler}>
        <div className="flex w-full flex-col gap-2 mt-2">
          <label htmlFor="account_number" className="block text-gray-700 text-sm font-bold mb-2">Current Password<span className='t text-red-600'>*</span></label>              
            <input
              type='password'
              id='currentPassword'
              placeholder='Current Password'
              className="w-full p-2 border border-gray-300 rounded-lg"  
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
          <div className="flex  w-full flex-col gap-2 mt-2">
          <label htmlFor="account_number" className="block text-gray-700 text-sm font-bold mb-2">Password<span className='t text-red-600'>*</span></label>            
            <input
              type='password'
              placeholder='New Password'
              id='newPassword'
              value={newPassword}
              className="w-full p-2 border border-gray-300 rounded-lg"  
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="flex w-full flex-col gap-2 mt-2">
          <label htmlFor="account_number" className="block text-gray-700 text-sm font-bold mb-2">Confirm Password<span className='t text-red-600'>*</span></label>             
            <input
              className="w-full p-2 border border-gray-300 rounded-lg"  
              type='password'
              placeholder='Confirm New Password'
              id='confirmPassword'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <button className='bg-green-800 text-black w-190 m-2 p-2 rounded-xl' type='submit'>
            Change Password
          </button>
        </form>
      </div>
       
    </div>
  );
}

export default Profile;
