import React, { useContext} from 'react';
import { useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { Signin } from '../Api';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { Store } from '../Store'
import logo from '../Asset/logo.jpg'
import { TypeAnimation } from 'react-type-animation';
import "./login.css";

const Login = () => {
      const navigate = useNavigate();
      const [number, setNumber] = useState('');
      const [password, setPassword] = useState('');      
      const { state, dispatch: ctxDispatch } = useContext(Store);
      const { userInfo } = state;
  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await Signin(number, password);
        console.log(response);    
        if (response.error) {          
          toast.error("incorret number or password");
        } else {
          ctxDispatch({ type: 'USER_SIGNIN', payload: response });
          localStorage.setItem('userInfo', JSON.stringify(response));
          toast.success('User Logged In Successfully');
          navigate('/home');
        }
      } catch (err) {
        toast.error(getError(err));
      }
    };    
  return (
    <div className='body flex min-h-screen bg-gray-200 text-black  w-full flex-col '>
    <div className="flex flex-col  w-full pt-24   h-screen  items-center">
    <div className='flex justify-center mt-11 items-center'>
                          <img src={logo} className=' h-[100px] border-2 border-textColor rounded-3xl' alt="" />   
                    </div>     
       
                    <h2 className="text-2xl text-center font-mono text-black italic font-bold mb-4">Coppera Metals</h2>
      <TypeAnimation
      sequence={[        
        'Welcome  ',
        1000, 
        'Welcome back  ',         
        1000,            
        'Welcome back login ',         
        1000,            
        'Welcome back login to',         
        1000,            
        'Welcome back login to your',         
        1000,            
        'Welcome back login to your account',         
        1000,            
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: "15px",textAlign:"center" , fontFamily: "italic", display: 'inline-block' }}
      repeat={Infinity}
    />
      <form className=" bg-white w-[80%] border-[3px] border-blue-200  rounded-lg shadow-md mt-11 p-6" onSubmit={handleSubmit}>
                       <div className="mb-4">  
                       <label className="block text-black items-center justify-center text-sm font-bold mb-2">Number <span  className='t text-red-600'>*</span></label>                        
                              <input   className="w-full p-2 border border-gray-300 rounded-lg"   type="number" placeholder='Number' required onChange={(e)=> setNumber(e.target.value)}  />                           
                        </div>
                       <div className="mb-4">           
                       <label className="block text-black text-sm font-bold mb-2">Password <span  className='t text-red-600'>*</span></label>                        
                              <input   className="w-full p-2 border border-gray-300 rounded-lg" type="password" placeholder='Password'  required onChange = {(e) => setPassword(e.target.value)} />                            
                        </div>
                       
                        <button type="submit "  className="bg-blue-900 text-white font-bold py-2 px-4 rounded"> Login</button>                                          
                          <div className='flex w-full text-slate-300 text-sm  gap-1'>                                    
              <p>I dont have an account?{' '}</p>
            <Link to={'/'}> <p   className="text-black font-bold  rounded">Register</p></Link>
      </div>  
                        <div className='flex items-center w-full flex-col justify-center'>                        
                              <i className="block text-black text-sm font-bold ">©copyright 2023  </i>
                              <i className="block text-black text-sm font-bold ">All Right Reserved</i>
                        </div>  
                  </form>
            </div>
  </div>
  )
}

export default Login
