import React from 'react'
import { useStateValue } from '../Context/StateProvider';
const About = () => {
  const [{ allProduct }, dispatch] = useStateValue();

  return (
    <div className="relative min-h-screen backdrop-blur-2xl">      
       <div className='w-full pb-[110px] min-h-screen px-2 text-sm text-white flex-col flex items-center justify-center'>      
        <div className="flex w-full justify-center py-3 my-4 bg-white rounded mx-4">
        <h1 className="flex items-center justify-center text-black text-2xl">About Us</h1>      
    </div>  
        <div  className='w-full'>
    <h1 className='text-center text-blue-800 font-bold text-2xl w-full'>Welcome to Coppera Metals</h1>
    <div className="mt-4   border items-center p-4 flex flex-col border-gree rounded-lg">
             <div className=' w-full justify-center  flex items-center mb-4 '>
                <p className='bg-textColor w-28 text-center rounded-sm p-1'>Price</p><div className='w-6 bg-white h-1'></div>
                <p className='bg-textColor w-28 text-center rounded-sm p-1'>Daily Earning </p><div className='w-6 bg-white h-1'></div>
                <p className='bg-textColor w-28 text-center rounded-sm p-1'>Total Earning </p>
             </div>
             {allProduct?.map((products)=>
               <div key={products._id} className='flex w-full justify-center  items-center mb-2'>
                <p className='bg-textColor w-28 text-center rounded-sm p-2'>₦{products?.invest_price}</p><div className='w-8 bg-white h-1'></div>
                <p className='bg-textColor w-28 text-center rounded-sm p-2'>₦ {products?.daily_amt} </p><div className='w-8 bg-white h-1'></div>
                <p className='bg-textColor w-28 text-center rounded-sm p-2'>₦ {products?.total_income} </p>
             </div>
             )
             }                               
            </div>        
            <h1 className='flex text-xl font-serif flex-col items-center mb-10 mt-5 gap-6  item-center justify-center'>                   
                    <ul className=' grid gap-6'>
                      <li>1. New user registration reward 300NGN.</li>
                      <li>2. The minimum withdrawal amount is 2000NGN.</li>
                      <li>3. The minimum investment is 3000NGN, and the daily income (600NGN)</li>
                      <li>4. Invite friends to invest and get 25% cash back.</li>                                          
                      <li>5. There is no time limit for withdrawal, and the number of daily withdrawals is not limited.</li>
                      <li>6. Withdrawal tax is 7% .</li>
                    </ul>
             
            </h1>
        </div>
    </div>
       
    </div>
   
  )
}

export default About
