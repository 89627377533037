import React, { useContext, useEffect} from 'react';
import moment from 'moment'; 
import { motion } from 'framer-motion';
import { Store } from '../Store';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from '../Context/StateProvider';
import { actionType } from '../Context/reducer';

const Order = (  ) => {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [{ totalIncome, investedIncome,referredIncome,user }, dispatch] = useStateValue();

  return (
    <div className="relative min-h-screen backdrop-blur-2xl">
    <div className="h-full pb-11">
       <div className="flex justify-center m py-3 px-5 my-4 bg-white rounded mx-4">
              <h1 className="flex items-center justify-center  text-2xl">Product</h1>      
        </div>
      <div className=" ">
                <div className='mt-10 flex-wrap flex justify-evenly mb-10 mx-2 gap-3'>
                <div className='text-center bg-green-500 rounded-lg p-2'>
                  <h3 className='text-white font-sm text-sm'>Total Income</h3>
                  <h3 className='text-white text-sm'>₦{totalIncome}</h3>
                </div>
                <div className='text-center bg-blue-500 rounded-lg p-2'>
                  <h3 className='text-white font-sm text-sm'>Invest Income</h3>
                  <h3 className='text-white text-sm'>₦{investedIncome}</h3>
                </div>
                {/* <div className='text-center bg-red-500 rounded-lg p-2'>
                  <h3 className='text-white font-sm text-sm'>Account Balance :</h3>
                  <h3 className='text-white text-sm'>{user? user.balance : 0}</h3>
                </div>
                <div className='text-center bg-slate-600 rounded-lg p-2'>
                  <h3 className='text-white font-sm text-sm'>Referrals Income</h3>
                  <h3 className='text-white text-sm'>₦{referredIncome}</h3>
                </div> */}
              </div>   
      </div>      
      <hr className='bg-white mt-3 h-2' />
      <hr className='bg-white mt-3 h-2' />
      <div className="h-full mb">
               <UserOrder   />
          </div>       
        </div>
    </div>
  );
};

export const UserOrder = () => {
  const navigate = useNavigate();
  const [{ allOrder }, dispatch] = useStateValue();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const data = allOrder ? allOrder.filter((obj) => obj?.user_id === userInfo?._id).reverse() : [];

const calculateDaysSinceApproval = (approvedDate) => {
  const currentDate =  moment(new Date()).format('l');
  const approvalDates =  moment(new Date(approvedDate)).format('l'); 
  const duration =  moment(currentDate).diff(approvalDates, 'days');;
  return duration;
};

  // useEffect(() => {
  //   let totalIncome = 0;
  //   const currTime = moment(new Date()).format('l');
  //   const paidData = data.filter((item) => item.paid === true);
  //   paidData.forEach((orderItem) => {
  //     const orderTime = moment(new Date(orderItem.updatedAt)).format('l');
  //     const timeDifference = moment(currTime).diff(orderTime, 'days');
  //     const dailyAmt = orderItem.daily_amt;
  //     const total = timeDifference * dailyAmt;
  //     totalIncome += total;
  //   });
  //   dispatch({
  //     type: actionType.SET_INVESTED_INCOME,
  //     investedIncome: totalIncome,
  //   });
  // }, [data, dispatch]);

  return (
    <div className=" pb-20">
      {data && data.length > 0 ? (
        data.map((orderItem) => (
          <div key={orderItem._id}>
            {orderItem.user_id === userInfo._id && (
              <motion.div
                initial={{ opacity: 0, translateX: -50 }}
                animate={{ opacity: 1, translateX: 0 }}
                className="relative w-[95vw] h-auto min-w-210 m-2 text-black p-2 cursor-pointer bg-white shadow-md rounded-lg flex items-center"
              >
                <div className="text-base w-full px-2 text-white">
                  <div className="flex justify-center w-full">
                    <h2 className="block text-sm text-black my-1">{orderItem?.name}</h2>
                  </div>

                  <div className="flex w-full">
                    <h2
                      className={`block text-sm ${
                        orderItem.canceled
                          ? 'bg-red-600'
                          : orderItem.paid
                          ? 'bg-green-600'
                          : 'bg-yellow-600'
                      } p-1 rounded font-bold m-2`}
                    >
                      {orderItem.canceled ? 'Canceled' : orderItem.paid ? 'Rebating' : 'Pending'}
                    </h2>
                  </div>

                  <div className="flex flex-col">
                    <div className="flex justify-between">
                      <h3 className="text-sm text-black">Daily Income:</h3>
                      <h3 className="text-sm text-black">₦{orderItem.daily_amt}</h3>
                    </div>
                    <div className="flex justify-between">
                      <h3 className="text-sm text-black">Total Income:</h3>
                      <h3 className="text-sm text-black">₦{orderItem.total_income}</h3>
                    </div>
                    <div className="flex justify-between">
                      <h3 className="text-sm text-black">Order Date:</h3>
                      <h3 className="text-sm text-black">
                        {moment(new Date(orderItem.createdAt)).format('l')}
                      </h3>
                    </div>
                    <div className="flex justify-between">
                      <h3 className="text-sm text-black">Revenue Service:</h3>
                      <h3 className="text-sm text-black">
                        {orderItem.paid
                          ? `${calculateDaysSinceApproval(orderItem.updatedAt)}/45 days`
                          : 'Pending'}
                      </h3>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </div>
        ))
      ) : (
        <div className="flex rounded-lg m-4 flex-col bg-white h-[200px] mt-10 items-center justify-center">
          <p className="text-xl font-semibold">You haven't rented any machine</p>
          <div className="flex justify-center">
            <motion.button
              whileTap={{ scale: 0.8 }}
              transition={{ duration: 0.3 }}
              type="button"
              onClick={() => navigate(`/home`)}
              className="bg-red-500 text-white w-190 m-2 p-2 rounded-xl"
            >
              Go Rent
            </motion.button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Order;
