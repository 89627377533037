import React from 'react'
import { Link } from 'react-router-dom';
import {GoHome} from 'react-icons/go';
import { MdSupportAgent } from "react-icons/md";
import {FcAbout} from 'react-icons/fc';
import { BsPerson } from 'react-icons/bs';
const Footer = () => {
  return (
    <div className={` bg-black z-40 py-4  w-full fixed bottom-0   text-textColor shadow-lg shadow-textColor`}>      
    <div className='flex w-full  text-center text-gray-400 text-2xl bg-black justify-around '>  
    <Link to={'/home'}className="flex flex-col justify-center items-center"><GoHome /><p className='text-sm '>Home</p></Link>
    <a   href="https://t.me/coppera_metals" target="_blank" rel="noopener noreferrer" className=" flex-col flex justify-center items-center"><MdSupportAgent /><p className='text-sm '>Telegram channel</p></a>    
    <Link  to={'/about'} className=" flex-col flex justify-center items-center"><FcAbout /><p className='text-sm '>Company profile</p></Link>        
    <Link to={'/me'} className=" flex-col flex justify-center items-center"><BsPerson /> <p className='text-sm '>My</p></Link>              
     </div>
    </div>
  )
}

export default Footer
