export const initialState = {
  user: null,
  allUser:null,
  product:null,
  allProduct:null ,
  allOrder:null,
  order:null,
  allPay:null,
  pay:null,
  referredIncome:0,
  payed:null,
  allDesposit:null,
  desposit:null,
  accBal: 0,
  pays:null,
  totalIncome:0,
  Payment:null,
  allPayment:null,
  DespPayment:null,
  allDespPayment:null,
  investedIncome:0
};
