import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useStateValue } from '../Context/StateProvider';
import { actionType } from '../Context/reducer';
import Footer from './footer';
import { MdDelete } from "react-icons/md";
import { motion } from 'framer-motion';
import { BiCloudUpload } from "react-icons/bi";
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { Store } from '../Store';
import Header from './Header';
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { storage } from "../configuration/firebase.configuration";

export const ImageLoader = ({ progress }) => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <p className="text-xl font-semibold text-textColor">
        {Math.round(progress) > 0 && <>{`${Math.round(progress)}%`}</>}
      </p>
      <div className="w-20 h-20 min-w-[40px] bg-textColor animate-ping  rounded-full flex items-center justify-center relative">
        <div className="absolute inset-0 rounded-full bg-textColor blur-xl "></div>
      </div>
    </div>
  );
};

export const ImageUploader = ({
  setImageURL,
  setAlert,
  alertMsg,
  isLoading,
  isImage,
  setProgress,
}) => {
  const uploadImage = (e) => {
    isLoading(true);
    const imageFile = e.target.files[0];
    const storageRef = ref(
      storage,
      `${isImage ? "Images" : "Audio"}/${Date.now()}-${imageFile.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        toast.error("File is not uploaded")  
        console.log(error);     
        isLoading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          setImageURL(downloadUrl);
          setProgress(0);
          isLoading(false);
        toast.success("File Uploaded Successfilly")         
        });
      }
    );
  };

  return (
    <label>
      <div className="flex mb-[110px] flex-col items-center justify-center h-full">
        <div className="flex flex-col justify-center items-center cursor-pointer">
          <p className="font-bold text-textColor  text-xl">
            <BiCloudUpload />
          </p>
          <p className="text-lg text-textColor">
            click to upload Screenshot
          </p>
        </div>
        <p className="text-[2px] italic mt-16 text-textColor">
            __Accept image with 4mb__
          </p>
      </div>
      <input
        type="file"
        name="upload-image"
        accept={`${isImage ? "image/*" : "audio/*"}`}
        onChange={uploadImage}
        className="w-0 h-0"
      />
    </label>
  );
};

export const DisabledButton = () => {
  return (
    <button
      disabled
      type="button"
      className="text-textColor bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
    >
      <svg
        role="status"
        className="inline w-4 h-4 mr-3 text-textColor animate-spin"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="#E5E7EB"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor"
        />
      </svg>
      Loading...
    </button>
  );
}

const Desposit = ({index}) => {
  const navigate = useNavigate();

  const { state } = useContext(Store);
  const {  userInfo } = state;
  
  const { BuyNowId } = useParams();
  const [{ product }, dispatch] = useStateValue();
  const [progress, setProgress] = useState(0);
  const [productImage, setProductImage] = useState(null);
  const [isProduct, setIsProduct] = useState(false);
  const [orderData, setOrderData] = useState({
    name: '',
    invest_price: '',
    imageURL: '',
    description: '',
    user_id: '',
    daily_amt: '',
    total_income: '',
    contract_cycle: '',
  });
  
  const [amount, setAmount] = useState('');  
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
//   useEffect(() => {
//     axios.get(`https://optimal-trend-miner.onrender.com/api/product/getOne/${BuyNowId}`).then((res) => {
//       dispatch({
//         type: actionType.SET_PRODUCT,
//         product: res.data,
//       });
//       setOrderData({
//         name: res.data.name,
//         invest_price: res.data.invest_price,
//         imageURL: res.data.imageURL,
//         description: res.data.description,
//         user_id: userInfo._id,
//         daily_amt: res.data.daily_amt,
//         total_income: res.data.total_income,
//         contract_cycle: res.data.contract_cycle,
//       });
//     });
//   }, [BuyNowId,userInfo,dispatch]);
// localStorage.setItem("order", JSON.stringify(orderData))
const handleSubmit = async (e) => {
  e.preventDefault();

  if (!amount || !productImage) {
    toast.warning('Required fields are missing');
  } else {
    try {
      const { data } = await axios.post(`https://optimistic-investment.onrender.com/api/payStack/save`, {
        name: userInfo?.name,
        number: userInfo?.number,
        amount: amount,
        imageURL:productImage,
        balance: userInfo?.balance,
        user_id: userInfo?._id,
      });
      setIsFormSubmitted(true);
      console.log(data);
      toast.success('Order placed successfully!');
    } catch (error) {
      const errorMessage = getError(error);
      toast.error(errorMessage);
    }
  }
};

  const handleCopyReferralCode = () => {
    const referralLink = 6369937413;
    navigator.clipboard.writeText(referralLink);
    toast.success('copied to clipboard!');
  };

  const deleteImageObject = (imageURL) => {
    setIsProduct(true);
    setProductImage(null);
    const deleteRef = ref(storage, imageURL);
    deleteObject(deleteRef).then(() => {
      toast.success("File removed successfully");    
      setIsProduct(false);
    });
  };
  return (
    <div className="h-auto mb-[110px]">
      <div className="flex justify-center py-3 px-5 my-4 bg-white rounded mx-4">
        <h1 className="flex items-center justify-center  text-2xl">Desposit</h1>      
    </div>
      
      <div className="flex h-full bg-white flex-col w-full">
        
          <div className="flex justify-center items-center flex-col">         

            <div className="text-base w-full px-2 mt-10 text-black">
              <div className="flex flex-col gap-5">
                <div className="flex justify-between">
                  <h3 className="text-xl font-medium  text-black">Bank Name:</h3>
                  <h3 className="text-xl font-medium  text-black">Monie Point Ventures</h3>
                </div>
                <div className="flex justify-between">
                  <h3 className="text-xl font-medium  text-black">Account Name:</h3>
                  <h3 className="text-xl font-medium  text-black">Skillfull Slate Ventures</h3>
                </div>
                <div className="flex justify-between">
                  <h3 className="text-xl font-medium  text-black">Account Number:</h3>
                  <h3 className="text-xl font-medium  text-black">6369937413</h3>
                </div>

             
              </div>
              <div className="flex justify-center">
          <motion.button
            whileTap={{ scale: 0.8 }}
            transition={{ duration: 0.3 }}
            type="button"
            onClick={handleCopyReferralCode}
            className="bg-textColor w-190 m-2 p-2 rounded-xl"
          >
            Copy Account Number
          </motion.button>
        </div>    
            </div>
          </div>
      

<div className="flex flex-col w-full mt-5 mb-[100px] h-full bg-gray-100">
        <h2 className="text-2xl text-center font-bold mb-4">Enter Your Account Details</h2>
        <span className='italic text-center text-red-500'>*Use your Internet/mobile Banking Platform from your bank to pay  into account*</span>
        <form className="mx-3" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-2 my-2">
            <label className="block text-gray-700 text-sm font-bold mb-2"htmlFor="account_name">Amount <span className='t text-red-600'>*</span></label>
            <input 
            className="w-full p-2 border border-gray-300 rounded-lg"  
              type="number"
              id="account_name"
              name="account_name"              
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>   
          <label className="block text-gray-700 text-sm font-bold mb-2"htmlFor="account_name">Payment Screenshot  <span className='t text-red-600'>*</span></label>
        <div className=" backdrop-blur-md bg-auto  w-full h-[200px] rounded-md border-2 bg-slate-300 border-dotted border-textColor cursor-pointer">
        {isProduct && <ImageLoader progress={progress} />}
        {!isProduct && (
          <>
            {!productImage ? (
              <ImageUploader
                setImageURL={setProductImage}              
                isLoading={setIsProduct}
                setProgress={setProgress}
                isImage={true}
              />
            ) : (
              <div className="relative w-full h-full overflow-hidden rounded-md">
                <img
                  src={productImage}
                  alt="uploaded_image"
                  className="w-full h-full bg-cover bg-center object-cover"
                />
                <button
                  type="button"
                  className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md  duration-500 transition-all ease-in-out"
                  onClick={() => {
                    deleteImageObject(productImage);
                  }}
                >
                  <MdDelete className="text-textColor" />
                </button>
              </div>
            )}
          </>
        )}
      </div>
        
                
          <button type="submit" onClick={handleSubmit} className="bg-textColor w-190 m-2 p-2 rounded-xl"
         disabled={isFormSubmitted}>
            {isFormSubmitted ? "Submitted" : "Submit Payment"}
          </button>
        </form>      
         
      </div>
    </div>
      </div>
    // </div>
  );
};

export default Desposit;

 

