import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import { StoreProvider } from './Store';
import { StateProvider } from "./Context/StateProvider";
import { initialState } from "./Context/initalState";
import reducer from "./Context/reducer";

const root =
 ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode className='bg-gradient-to-r from-cyan-500 to-green-500'> 
       <StateProvider initialState={initialState} reducer={reducer}>
          <StoreProvider>
                <Router>
                  <App />
            </Router>
        </StoreProvider>  
       </StateProvider>  
  </React.StrictMode>  
);
