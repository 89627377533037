/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SignUp from './component/SignUp';
import Login from './component/Login';
import Home from './component/Home';
import Me from './component/me';
import DashPay from './component/dashPay';
import Order from './component/order';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/ReactToastify.css';
import About from './component/About';
import Buynow from './component/Buynow';
import Dashboard from './component/Dashboard';
import DashUser from './component/dashUser';
import DashOrder from './component/dashOrder';
import DashProduct from './component/dashProduct';
import Footer from './component/footer';
import { actionType } from './Context/reducer';
import { useStateValue } from './Context/StateProvider';
import { useContext } from 'react';
import { Store } from './Store';
import DashDesposit from './component/dashDesposit';
import DashPayment from './component/dashPayment';
import PaymentDetail from './component/paymentDetail';
import PayDetail from './component/payDetail';
import moment from 'moment';
import './App.css';
import Payment from './component/Payment';
import Referral from './component/referral';
import { getAllOrder, getAllPayRequest, getAllUser } from './Api';
import Profile from './component/Profile';
import HistoryW from './component/history';
import WithHistory from './component/Withhistory';
import axios from 'axios';
import Desposit from './component/Desposit';
import PayDesposit from './component/PayDesposit';
import PaymentUpdateDelete from './component/WithdrawDetails';

function App() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [{ user, allPay, referredIncome, allDespPayment, desposit, allOrder, investedIncome, allUser }, dispatch] = useStateValue();

  // Fetch necessary data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderData = await getAllOrder();
        dispatch({
          type: actionType.SET_ALL_ORDER,
          allOrder: orderData?.order,
        });

        await axios.get(`https://optimistic-investment.onrender.com/api/user/me/${userInfo._id}`).then((res) => {
          dispatch({
            type: actionType.SET_USER,
            user: res?.data,
          });
        });

        await axios.get(`https://optimistic-investment.onrender.com/api/payStack/getAll`).then((res) => {
          dispatch({
            type: actionType.SET_DESPOSIT,
            desposit: res.data,
          });
        });

        await axios.get(`https://optimistic-investment.onrender.com/api/desposit/getAll`).then((res) => {
          dispatch({
            type: actionType.SET_ALL_DESP_PAYMENT,
            allDespPayment: res.data,
          });
        });

        const payData = await getAllPayRequest();
        dispatch({
          type: actionType.SET_PAY,
          allPay: payData?.payment,
        });

        const userData = await getAllUser();
        dispatch({
          type: actionType.SET_ALL_USERS,
          allUser: userData?.users,
        });
      } catch (error) {
        toast.error(error);
      }
    };

    if (!allOrder || !user || !allPay || !desposit || !allUser) {
      fetchData();
    }
  }, [allOrder, user, allPay, allUser, dispatch]);

  // Calculate balance
  useEffect(() => {
    if (allOrder && allPay && allUser) {
      let totalInvestedIncome = 0;
      let totalReferredIncome = 0;
      const filteredData = allOrder ? allOrder.filter((obj) => obj?.user_id === userInfo?._id) : [];
      const despData = allDespPayment ? allDespPayment.filter((obj) => obj?.user_id === userInfo?._id) : [];
      const paidDespData = despData.filter(item => item.paid === true);
      let totalIncome = 0;
      let totalOrder = 0;
      const currTime = moment(new Date()).format('l');
      const paidData = filteredData.filter(item => item.paid === true);

      for (let key in paidData) {
        const orderTime = moment(new Date(paidData[key].updatedAt)).format('l');
        let timeDifference = moment(currTime).diff(orderTime, 'days');

        // Ensure it adds up daily until it reaches 45 days
        if (timeDifference > 0 && timeDifference <= 45) {
          const dailyAmt = paidData[key].daily_amt;
          const total = timeDifference * dailyAmt;
          totalIncome += total;
        } else if (timeDifference > 45) {
          const dailyAmt = paidData[key].daily_amt;
          totalIncome += 45 * dailyAmt; // Cap at 45 days' worth of income
        }
      }

      for (let key in filteredData) {
        totalOrder += filteredData[key].invest_price;
      }

      const totbal = totalIncome;
      dispatch({
        type: actionType.SET_INVESTED_INCOME,
        investedIncome: totbal,
      });

      console.log(totalOrder);

      const referredPeople = allUser ? allUser.filter((obj) => obj?.code === userInfo?.referralCode) : [];
      const paidReferredOrders = allOrder ? allOrder.filter((order) => referredPeople.some((person) => person?._id === order?.user_id && order?.paid)) : [];
      
      const processedUserIds = new Set();
      let referredInco = 0;

      paidReferredOrders.forEach((order) => {
        if (!processedUserIds.has(order.user_id)) {
          referredInco += (order?.invest_price * 0.25) || 0;
          processedUserIds.add(order.user_id);
        }
      });

      dispatch({
        type: actionType.SET_ALL_REFERRED_INCOME,
        referredIncome: referredInco,
      });

      const filteredDat = allPay ? allPay?.filter((obj) => obj?.user_id === userInfo?._id) : [];
      const paid = filteredDat.filter(item => item.isPaid === true);
      const totalAmount = filteredDat.reduce((total, obj) => total + (obj.amount || 0), 0);
      const totalDesposit = despData.reduce((total, obj) => total + (obj.amount || 0), 0);
      const userBalance = user?.balance;
      const totalBalance = investedIncome + referredIncome + 300 - totalAmount;

      const rBalance = totalBalance;

      dispatch({
        type: actionType.SET_TOTAL_INCOME,
        totalIncome: rBalance,
      });
    }
  }, [allOrder, allPay, allUser, dispatch]);

  return (
    <div className="app-container h-full">
      <ToastContainer position="bottom-right" limit={1} />
      <div className="content">
        <div>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<SignUp />} />
          </Routes>
        </div>
        <div>
          <div>
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/me" element={<Me />} />
              <Route path="/dashboard/*" element={<Dashboard />} />
              <Route path="/dashuser" element={<DashUser />} />
              <Route path="/paydesp" element={<PayDesposit />} />
              <Route path="/dashorder" element={<DashOrder />} />
              <Route path="/dashproduct" element={<DashProduct />} />
              <Route path="/dashdeposit" element={<DashDesposit />} />
              <Route path="/dashdeposit/:id" element={<PaymentUpdateDelete />} />
              <Route path="/order" element={<Order />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/about" element={<About />} />
              <Route path="/referral" element={<Referral />} />
              <Route path="/desposit" element={<Desposit />} />
              <Route path="/history" element={<HistoryW />} />
              <Route path="/withdhistory" element={<WithHistory />} />
              <Route path="/buynow/:BuyNowId" element={<Buynow />} />
              <Route path="/paylist" element={<DashPay />} />
              <Route path="/paymentitems" element={<DashPayment />} />
              <Route path="/paymentitems/:id" element={<PaymentDetail />} />
              <Route path="/pay/:id" element={<PayDetail />} />
            </Routes>
          </div>
          {/* Render Footer except on Login and SignUp pages */}
          {window.location.pathname !== '/login' && window.location.pathname !== '/' && <Footer />}
        </div>
      </div>
    </div>
  );
}

export default App;

