import React, { useEffect } from 'react';
import { NavLink, Route, Routes } from 'react-router-dom'
import { isActiveStyles, isNotActiveStyles } from "../utils";
import Footer from './footer';
import { useStateValue } from '../Context/StateProvider';
import { getAllOrder, getAllUser,getAllProduct, getAllPayRequest } from '../Api';
import { actionType } from '../Context/reducer';
import DashUser from './dashUser';
import DashOrder from './dashOrder';
import DashProduct from './dashProduct';
import DashPay from './dashPay';
import DashPayment from './dashPayment';
import PaymentDetail from './paymentDetail';
import Paymentlist from './paymentlist';
import PayDetail from './payDetail';
import DespositDetail from './despositDetails';
import UserDetails from './UserDetails';
export const DashboardCards = ({ icon, name, count }) => {
  return (
    <div className="p-4 gap-3 h-auto rounded-lg shadow-md flex justify-between items-center  bg-gray-500 text-black">
      {icon}
      <p className='text-sm text-black '>{name}</p>
      <p className='text-sm text-black'>{count}</p>
    </div>
  );
};

const Dashboard = () => {
  const [{ allProduct,allOrder,allUser ,allPay}, dispatch] = useStateValue();
  useEffect(() => {
    if (!allProduct) {
      getAllProduct().then((data) => {
        dispatch({
          type: actionType.SET_ALL_PRODUCT,
          allProduct: data?.product,
        });        
      });
    }
  }, [allProduct,dispatch]);
  useEffect(() => {
    if (!allOrder) {
      getAllOrder().then((data) => {
        dispatch({
          type: actionType.SET_ALL_ORDER,
          allOrder: data?.order,
        });        
      });
    }
  }, [allOrder,dispatch]);
  useEffect(() => {
    if (!allUser) {
      getAllUser().then((data) => {
        dispatch({
          type: actionType.SET_ALL_USERS,
          allUser: data?.users
        });    
      });
    }
  }, [allUser,dispatch]);
  useEffect(() => {
    if (!allPay ) {
      getAllPayRequest().then((data) => {
        dispatch({
          type: actionType.SET_PAY,
          allPay: data
        });                 
      });
    }
  }, [allPay,dispatch]);
  return (
    <div className='w-full scroll-x-none h-full'>  
    <div className="relative min-h-screen backdrop-blur-2xl">

           <div className='flex justify-center w-full '>
                  <h2 className="block text-xl text-white font-medium  my-1">Admin DashBoard</h2>
                  </div>       
      <div className="w-full h-full flex flex-col items-center justify-center bg-gray-100">
    <div className="w-full   p-1 flex items-center justify-evenly text-black">    
      <NavLink to={'/dashboard/dashuser'} className={({ isActive }) => isActive ? isActiveStyles : isNotActiveStyles }><small className='text-black'>User</small> </NavLink>    
      <NavLink to={'/dashboard/dashproduct'} className={({ isActive }) => isActive ? isActiveStyles : isNotActiveStyles }><small className='text-black'>Products</small> </NavLink>
      <NavLink to={'/dashboard/dashorder'} className={({ isActive }) => isActive ? isActiveStyles : isNotActiveStyles }><small className='text-black'>Orders</small>  </NavLink>
      <NavLink to={'/dashboard/paymentitems'} className={({ isActive }) => isActive ? isActiveStyles : isNotActiveStyles }><small className='text-black'>Payment</small>  </NavLink>
      <NavLink to={'/dashboard/paylist'} className={({ isActive }) => isActive ? isActiveStyles : isNotActiveStyles }><small className='text-black'>Withdrawers</small> </NavLink>  
    </div>
    <div className="my-4 h-full w-fullp-4">
      <Routes>
        <Route path='/dashuser' element={<DashUser />} />
        <Route path='/dashproduct' element={<DashProduct />} />
        <Route path='/dashorder'element={<DashOrder  />} />
        <Route path='/paylist' element={<DashPay/>} />
      
        <Route path='/depositlist' element={<Paymentlist/>} />
        <Route path='/depositlist/:id' element={<DespositDetail/>} />
        <Route path='/paylist' element={<DashPay/>} />      
        <Route path='/user/:id' element={<UserDetails/>} />            
        <Route path='/paymentitems' element={<DashPayment />} />    
        <Route path='/paymentitems/:id' element={<PaymentDetail />} />    
        <Route path='/paymentitems' element={<DashPayment />} />    
        <Route path='/paymentitems/:id' element={<PaymentDetail />} />    
        <Route path='/paylist/:id' element={<PayDetail />} />    
      </Routes>
    </div>
    <Footer/>
  </div>
  </div>
   
    </div>
  ); 
};

export default Dashboard;
