import React, { useContext,useEffect, useState } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import { useStateValue } from '../Context/StateProvider';
import { motion } from 'framer-motion';
import axios from 'axios';
import moment from 'moment'; 
import Footer from './footer';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import { actionType } from '../Context/reducer';
import { getError } from '../utils';

const PaymentDetail = (data) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [{ pay }, dispatch] = useStateValue();
  const [paymentButtonDisabled, setPaymentButtonDisabled] = useState(false);
  const [rejectButtonDisabled, setRejectButtonDisabled] = useState(false);
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);

  const { state } = useContext(Store);
  const { userInfo } = state;  
    useEffect(() => {
      axios.get(`https://optimistic-investment.onrender.com/api/order/getOne/${id}`).then((res) => {
        dispatch({
          type: actionType.SET_ALL_PAY,
          pay: res.data,
        });        
      });
    }, [pay,dispatch,id]);
    const handlePayment = async() => {
      try {
        if(!paymentButtonDisabled){
            dispatch({ type: 'PAY_REQUESTS' });
        const { data } = await axios.put(
          `https://optimistic-investment.onrender.com/api/order/${id}/paid`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setPaymentButtonDisabled(true);
        toast.success('Payment completed!')
        navigate("/me") 
        window.location.reload();
        navigate("/dashboard/depositlist")
        dispatch({ type: 'PAY_SUCCESS', payload: data });
        }
      
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: 'PAY_FAIL', payload: getError(err) });
      }
    }
    const handleReject = async() => {
      try {
        if(!rejectButtonDisabled){
            dispatch({ type: 'PAY_REQUESTS' });
        const { data } = await axios.put(
          `https://optimistic-investment.onrender.com/api/order/${id}/reject`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setRejectButtonDisabled(true)
        toast.success('Payment not approved!')
        dispatch({ type: 'PAY_SUCCESS', payload: data });
        }
      
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: 'PAY_FAIL', payload: getError(err) });
      }
    }
    const handleCancel = async() => {
      try {
      if(!cancelButtonDisabled){
        handleReject()
        dispatch({ type: 'PAY_REQUESTS' });
        const { data } = await axios.put(
          `https://optimistic-investment.onrender.com/api/order/${id}/cancel`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setCancelButtonDisabled(true);
        setPaymentButtonDisabled(true);
        setRejectButtonDisabled(true)
        toast.success('Payment Canceled!')
        dispatch({ type: 'PAY_SUCCESS', payload: data });
      }
      
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: 'PAY_FAIL', payload: getError(err) });
      }
    }

    


  return (
    <div className='flex h-full flex-col mb-20 overflow-auto'>  
        {pay&&
             <motion.div
              key={pay?._id}      
              initial={{ opacity:1, translateX: -50 }}
              animate={{ opacity: 1, translateX: 0 }}
              transition={{ duration: 0.3 }}
              className="relative m-2 w-[95vw] min-w-210 flex-col  p-2 cursor-pointer tra  bg-white shadow-md rounded-lg flex items-center" 
            > 
            <a href={pay.imageURL} target="_blank" rel="noopener noreferrer">              
            <div className="w-30 min-w-[120px] h-[200px]  rounded-lg drop-shadow-lg relative overflow-hidden ">
                <motion.img
                  whileHover={{ scale: 1.05 }}
                  src={pay.imageURL}
                  alt="proof of payment"
                  className="w-full h-full bg-white rounded-lg object-cover"
                />
          </div>
            </a>
            <div className="text-base w-full px-2 text-black">    
                <div className='flex justify-center w-full '>
                  <h2 className="block text-xl font-medium   my-1">{pay?.user_name}</h2>
                  </div>  
                  <div className='flex text-xl font-medium flex-col '>                    
                    <div className='flex justify-between'>
                       <h3 className=' text-xl font-medium '>Account Name:</h3>
                       <h3 className=' text-xl font-medium  text-black'>{pay?.account_name}</h3>
                    </div>                   
                    <div className='flex justify-between'>
                       <h3 className=' text-xl font-medium '>Machine Price:</h3>
                       <h3 className=' text-xl font-medium  text-black'> ₦{pay?.invest_price}</h3>
                    </div>
                    <div className='flex justify-between'>
                       <h3 className=' text-xl font-medium '>Date:</h3>
                       <h3 className=' text-xl font-medium  text-black'> {moment(new Date(pay.createdAt)).format('l')}</h3>
                    </div>                      
                  </div> 
                                                      
            </div>      
           </motion.div>   
        }
          {pay && !pay.paid && (
                  <div className='flex justify-center'>
                        <motion.button 
                        whileTap={{ scale: 0.8 }}        
                        transition={{ duration: 0.3}}
                        type='button' 
                        className=' bg-green-500  text-black w-190 m-2 p-2 rounded-xl' 
                        onClick={paymentButtonDisabled ? null : handlePayment}
                        disabled={paymentButtonDisabled}
                    >
                      Payment Completed
                    </motion.button>  
                    </div> )}
                    {pay && pay.paid && (
                    <div className='flex justify-center'>
                        <motion.button 
                        whileTap={{ scale: 0.8 }}        
                        transition={{ duration: 0.3}}
                        type='button' 
                        className=' bg-red-500  text-black w-190 m-2 p-2 rounded-xl' 
                        onClick={rejectButtonDisabled? null: handleReject}
                        disabled={rejectButtonDisabled}
                    >
                     Not Comfirmed Payment 
                    </motion.button>  
                    </div> )}
                  <div className='flex justify-center'>
                        <motion.button 
                        whileTap={{ scale: 0.8 }}        
                        transition={{ duration: 0.3}}
                        type='button' 
                        className=' bg-red-500  text-black w-190 m-2 p-2 rounded-xl' 
                        onClick={cancelButtonDisabled? null : handleCancel}
                        disabled={cancelButtonDisabled}
                    >
                     Cancel Payment 
                    </motion.button>  
                    </div> 
       
    </div>
  );
};

export default PaymentDetail;
