import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStateValue } from '../Context/StateProvider';
import { motion } from 'framer-motion';
import axios from 'axios';
import Footer from './footer';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import { actionType } from '../Context/reducer';
import { getError } from '../utils';




const PayDetail = (data) => {
  const { id } = useParams();
  const [paymentButtonDisabled, setPaymentButtonDisabled] = useState(false);
  const [rejectButtonDisabled, setRejectButtonDisabled] = useState(false);
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);
  const [{payment }, dispatch] = useStateValue();  
  const { state} = useContext(Store);
  const { userInfo } = state;  
    useEffect(() => {
      axios.get(`https://optimistic-investment.onrender.com/api/payment/getOne/${id}`).then((res) => {        
        dispatch({
          type: actionType.SET_ALL_PAYMENT,
          payment: res.data,
        });        
      });
    }, [dispatch,id]);
    const handlePayment = async() => {
      try {
        if(!paymentButtonDisabled){
        dispatch({ type: 'PAY_REQUESTSSSS' });
        const { data } = await axios.put(
          `https://optimistic-investment.onrender.com/api/payment/${id}/paid`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );        
        setPaymentButtonDisabled(true);
        setRejectButtonDisabled(false)
        toast.success('Payment completed!')
        dispatch({ type: 'PAY_SUCCESSSS', payload: data });
        }
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: 'PAY_FAILS', payload: getError(err) });
      }
    }

    const handleReject = async() => {
      try {
        if(!rejectButtonDisabled){
        dispatch({ type: 'PAY_REQUESTSSSS' });
        const { data } = await axios.put(
          `https://optimistic-investment.onrender.com/api/payment/${id}/reject`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setRejectButtonDisabled(true)
        setPaymentButtonDisabled(false);
        toast.success('Payment Canceled!')
        dispatch({ type: 'PAY_SUCCESSSS', payload: data });
        }
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: 'PAY_FAILS', payload: getError(err) });
      }
    }
    const handleCancel = async() => {
      try {
        if(!cancelButtonDisabled){
          handleReject()
        dispatch({ type: 'PAY_REQUESTSS' });
        const { data } = await axios.put(
          `https://optimistic-investment.onrender.com/api/payment/${id}/cancel`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setCancelButtonDisabled(true);
        setPaymentButtonDisabled(true);
        setRejectButtonDisabled(true)
        toast.success('Payment Canceled!')
        dispatch({ type: 'PAY_SUCCESS', payload: data });
        }
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: 'PAY_FAIL', payload: getError(err) });
      }
    }

    


  return (
    <div className='flex h-full flex-col mb-20 overflow-auto'>  
        {payment&&
             <motion.div
              key={payment?._id}      
              initial={{ opacity:1, translateX: -50 }}
              animate={{ opacity: 1, translateX: 0 }}
              transition={{ duration: 0.3 }}
              className="relative m-2 w-[95vw] min-w-210 p-2 cursor-pointer tra  bg-white shadow-md rounded-lg flex items-center" 
            > 
            <div className="text-base w-full px-2 text-black">    
                <div className='flex justify-center w-full '>
                  <h2 className="block text-xl font-medium   my-1">{payment?.user_name}</h2>
                  </div>  
                  <div className='flex text-xl font-medium flex-col '>
                    <div className='flex justify-between'>
                       <h3 className=' text-xl font-medium '>Bank Name:</h3>
                       <h3 className=' text-xl font-medium  '>{payment?.bank_name}</h3>
                    </div>
                    <div className='flex justify-between'>
                       <h3 className=' text-xl font-medium '>Account Name:</h3>
                       <h3 className=' text-xl font-medium  text-black'>{payment?.account_name}</h3>
                    </div>
                    <div className='flex justify-between'>
                       <h3 className='  text-xl font-medium '>Account Number:</h3>
                       <h3 className=' text-xl font-medium  text-black'>{payment?.account_number}</h3>
                    </div>
                    <div className='flex justify-between'>
                       <h3 className='  text-xl font-medium '>Amount :</h3>
                       <h3 className=' text-xl font-medium  text-black'>{payment?.amount}</h3>
                    </div>
                    <div className='flex justify-between'>
                       <h3 className='  text-xl font-medium '>Amount After Tax :</h3>
                       <h3 className=' text-xl font-medium  text-black'>{parseFloat(payment?.amount - (payment?.amount * 0.07)).toFixed(2)}</h3>
                    </div>                  
                    <div className='flex justify-between'>
                       <h3 className=' text-xl font-medium '>User Total balance:</h3>
                       <h3 className=' text-xl font-medium  text-black'> {payment?.balance}</h3>
                    </div>                      
                  </div> 
                                                      
            </div>      
           </motion.div>   
        }
        {payment && !payment.isPaid && (
                    <div className='flex justify-center'>
                        <motion.button 
                        whileTap={{ scale: 0.8 }}        
                        transition={{ duration: 0.3}}
                        type='button' 
                        className=' bg-green-500  text-black w-190 m-2 p-2 rounded-xl' 
                        onClick={paymentButtonDisabled ? null : handlePayment}
                        disabled={paymentButtonDisabled}
                    >
                      Payment Completed
                    </motion.button>  
                    </div> )}
                    {payment && payment.isPaid && (
                    <div className='flex justify-center'>
                        <motion.button 
                        whileTap={{ scale: 0.8 }}        
                        transition={{ duration: 0.3}}
                        type='button' 
                        className=' bg-red-500  text-black w-190 m-2 p-2 rounded-xl' 
                        onClick={rejectButtonDisabled? null: handleReject}
                        disabled={rejectButtonDisabled}
                    >
                     Not Comfirmed Payment 
                    </motion.button>  
                    </div> )}
                  <div className='flex justify-center'>
                        <motion.button 
                        whileTap={{ scale: 0.8 }}        
                        transition={{ duration: 0.3}}
                        type='button' 
                        className=' bg-red-500  text-black w-190 m-2 p-2 rounded-xl' 
                        onClick={cancelButtonDisabled? null : handleCancel}
                        disabled={cancelButtonDisabled}
                    >
                     Cancel Payment 
                    </motion.button>  
                    </div> 
       
    </div>
  );
};

export default PayDetail;
