import React, { useContext, useEffect,useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStateValue } from '../Context/StateProvider';
import { motion } from 'framer-motion';
import axios from 'axios';
import Footer from './footer';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import { actionType } from '../Context/reducer';
import { getError } from '../utils';

const DespositDetail = (data) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [paymentButtonDisabled, setPaymentButtonDisabled] = useState(false);
  const [rejectButtonDisabled, setRejectButtonDisabled] = useState(false);
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);
  const [{DespPayment, user}, dispatch] = useStateValue();  
  const { state} = useContext(Store);  
  const { userInfo } = state;  
    useEffect(() => {
      axios.get(`https://optimistic-investment.onrender.com/api/desposit/getOne/${id}`).then((res) => {              
        dispatch({
          type: actionType.SET_DESP_PAYMENT,
          DespPayment: res.data,
        });     
        console.log(res.data);
      });
    }, [dispatch,id]);
    const handledesposit= async() => {        
      try {
        if(!paymentButtonDisabled){
        let leftBal ;
        let newBal ;
        if (DespPayment && user){
            leftBal=user.balance
            newBal= leftBal +DespPayment.amount
        }
        dispatch({ type: 'PAY_REQUESTSSSS' });
        const {data}= await axios.put(`https://optimistic-investment.onrender.com/api/user/${userInfo?._id}`, {      
            balance: newBal
          },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }  
          );
          await axios.put(
            `https://optimistic-investment.onrender.com/api/desposit/${id}/paid`,
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          );
          dispatch({
            type: actionType.SET_USER,
            user: data,
          })          
             setPaymentButtonDisabled(true);    
        toast.success('Desposit completed!')
        navigate("/me") 
        window.location.reload();
        navigate("/dashboard/depositlist") 
        dispatch({ type: 'PAY_SUCCESSSS', payload: data });
        }
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: 'PAY_FAILS', payload: getError(err) });
      }
    }

    const handleReject = async () => {
        try {
            if(!rejectButtonDisabled){
          let leftBal;
          let newBal;
          if (DespPayment&&user) {
            leftBal = user.balance;
            const amountToSubtract = DespPayment.amount;
      
            // Ensure that the subtraction doesn't result in a negative balance
            newBal = Math.max(leftBal - amountToSubtract, 0);
          }
      
          dispatch({ type: 'PAY_REQUESTSSSS' });
      
          const { data } = await axios.put(
            `https://optimistic-investment.onrender.com/api/user/${userInfo?._id}`,
            {
              balance: newBal,
            },
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          );
      
          await axios.put(
            `https://optimistic-investment.onrender.com/api/desposit/${id}/reject`,
            {},
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          );
      
          dispatch({
            type: actionType.SET_USER,
            user: data,
          });
          setRejectButtonDisabled(true)
          toast.success('Deposit Rejected!');
          navigate("/me") 
        window.location.reload();
        navigate("/dashboard/depositlist")
          dispatch({ type: 'PAY_SUCCESSSS', payload: data });
        }
        } catch (err) {
          toast.error(getError(err));
          dispatch({ type: 'PAY_FAILS', payload: getError(err) });
        }
      };
      
    const handleCancel = async() => {
        try {
            if(!cancelButtonDisabled){
            handleReject()
        dispatch({ type: 'PAY_REQUESTSS' });
        const { data } = await axios.put(
          `https://optimistic-investment.onrender.com/api/desposit/${id}/cancel`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setCancelButtonDisabled(true);
        setPaymentButtonDisabled(true);
        setRejectButtonDisabled(true)
        toast.success('Desposit Canceled!')
        navigate("/me") 
        window.location.reload();
        navigate("/dashboard/depositlist")
        dispatch({ type: 'PAY_SUCCESS', payload: data });
            }
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: 'PAY_FAIL', payload: getError(err) });
      }
    }

    


  return (
    <div className='flex h-full flex-col mb-20 overflow-auto'>  
        {DespPayment&&user&&
             <motion.div
              key={DespPayment?._id}      
              initial={{ opacity:1, translateX: -50 }}
              animate={{ opacity: 1, translateX: 0 }}
              transition={{ duration: 0.3 }}
              className="relative m-2 w-[95vw] flex-col min-w-210 p-2 cursor-pointer tra  bg-white shadow-md rounded-lg flex items-center" 
            > 
               {/* <a href={DespPayment.imageURL} target="_blank" rel="noopener noreferrer">              
            <div className="w-30 min-w-[120px] h-[200px]  rounded-lg drop-shadow-lg relative overflow-hidden ">
                <motion.img
                  whileHover={{ scale: 1.05 }}
                  src={DespPayment.imageURL}
                  alt="proof of payment"
                  className="w-full h-full bg-white rounded-lg object-cover"
                />
          </div>
            </a> */}
            <div className="text-base w-full px-2 text-black">    
                <div className='flex justify-center w-full '>
                  <h2 className="block text-xl font-medium   my-1">{DespPayment?.name}</h2>
                  </div>  
                  <div className='flex text-xl font-medium flex-col '>
                    <div className='flex justify-between'>
                       <h3 className=' text-xl font-medium '>User Id:</h3>
                       <h3 className=' text-xl font-medium  '>{DespPayment?._id}</h3>
                    </div>
                    <div className='flex justify-between'>
                       <h3 className=' text-xl font-medium '>User Name:</h3>
                       <h3 className=' text-xl font-medium captalize text-black'>{DespPayment?.name}</h3>
                    </div>
                    <div className='flex justify-between'>
                       <h3 className='  text-xl font-medium '>Number:</h3>
                       <h3 className=' text-xl font-medium  text-black'>{DespPayment?.number}</h3>
                    </div>
                    <div className='flex justify-between'>
                       <h3 className='  text-xl font-medium '>Amount :</h3>
                       <h3 className=' text-xl font-medium  text-black'>{DespPayment?.amount}</h3>
                    </div>                         
                    <div className='flex justify-between'>
                       <h3 className=' text-xl font-medium '>User Total balance:</h3>
                       <h3 className=' text-xl font-medium  text-black'> {user?.balance}</h3>
                    </div>                      
                  </div> 
                                                      
            </div>      
           </motion.div>   
        }
        
        {DespPayment && !DespPayment.isPaid && (
                  <div className='flex justify-center'>
                        <motion.button 
                        whileTap={{ scale: 0.8 }}        
                        transition={{ duration: 0.3}}
                        type='button' 
                        className=' bg-green-500  text-black w-190 m-2 p-2 rounded-xl' 
                        onClick={paymentButtonDisabled ? null : handledesposit}
                        disabled={paymentButtonDisabled}
                    >
                      Desposit Completed
                    </motion.button>  
                    </div> )}
                    {DespPayment && DespPayment.isPaid && (
                  <div className='flex justify-center'>
                        <motion.button 
                        whileTap={{ scale: 0.8 }}        
                        transition={{ duration: 0.3}}
                        type='button' 
                        className=' bg-red-500  text-black w-190 m-2 p-2 rounded-xl' 
                        onClick={rejectButtonDisabled? null:handleReject}
                        disabled={rejectButtonDisabled}
                    >
                     Not Comfirmed Desposit
                    </motion.button>  
                    </div> )}
                    <div className='flex justify-center'>
                        <motion.button 
                        whileTap={{ scale: 0.8 }}        
                        transition={{ duration: 0.3}}
                        type='button' 
                        className=' bg-red-500  text-black w-190 m-2 p-2 rounded-xl' 
                        onClick={cancelButtonDisabled? null :handleCancel}
                        disabled={cancelButtonDisabled}
                    >
                     Cancel Desposit
                    </motion.button>  
                    </div> 
       
    </div>
  );
};

export default DespositDetail;
