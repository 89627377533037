import React, { useState,useContext, useEffect } from 'react';
import axios from 'axios';
import { useStateValue } from '../Context/StateProvider';
import { Store } from '../Store';
import { actionType } from '../Context/reducer';
import {  useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';




function PaymentUpdateDelete() {
    const { id } = useParams();
    const [{ pay }, dispatch] = useStateValue();
    const { state } = useContext(Store);
    const { userInfo } = state;    
    const navigate = useNavigate();        
    const [bankCode, setBankCode] = useState('');
  const [payment, setPayment] = useState({});
  const [bankList, setBankList] = useState([]);

  const [updateData, setUpdateData] = useState({
    account_name: '',
    account_number: '',
    bank_name: '',
  });
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await fetch('https://api.paystack.co/bank', {
          method: 'GET',
          headers: {
            Authorization: `Bearer YOUR_PAYSTACK_SECRET_KEY`,
          },
        });

        const data = await response.json();
        setBankList(data.data);
      } catch (error) {
        console.error('Error fetching banks:', error);
      }
    };
    fetchBanks();
  }, []);
  useEffect(() => {
    // Load the payment data when the component mounts
    axios.get(`https://optimistic-investment.onrender.com/api/payStack/getOne/${id}`).then((response) => {        
      setPayment(response.data);
      setUpdateData({
        account_name: response?.data.account_name,
        account_number: response?.data.account_number,
        bank_name: response?.data.bank_name,
      });
    });
  }, [id]);

  const handleUpdate = () => {
    axios
      .put(`https://optimistic-investment.onrender.com/api/payStack/${id}`, updateData,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      })
      .then((response) => {
          navigate(`/dashdeposit`)
          window.location.reload();
       toast.success('Payment updated:', response.data);
      })
      .catch((error) => {
        // Handle error
       toast.error('Failed to update payment:', error);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`https://optimistic-investment.onrender.com/api/payStack/${id}`,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      })
      .then(() => {
        // Handle success
        navigate(`/dashdeposit`)
        window.location.reload();
       toast.success('Payment deleted');
      })
      .catch((error) => {
        // Handle error
       toast.error('Failed to delete payment:', error);
      });
  };

  return (
    <div className="relative min-h-screen backdrop-blur-2xl">
    <div  className="flex flex-col justify-center items-center h-screen">
         {/* <div className="flex justify-center m py-3 px-5 my-4 bg-white rounded mx-4">
     <h1 className="flex items-center justify-center text-black  text-2xl">Edit Account</h1>      
 </div> */}
                   <div className="flex flex-col w-full mb-20 h-full bg-gray-100">        
        <div className="w-full h-full bg-white rounded-lg shadow-md p-6">                                     
        <div className="flex flex-col w-full mt-5 mb-20 h-full bg-gray-100">
        <h2 className="text-2xl text-center font-bold mb-4">Enter New Account Details</h2>      
        <form className="mx-3" onSubmit={handleUpdate}>
        <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Bank Name <span  className='t text-red-600'>*</span></label>
        <select
          className="w-full p-2 border border-gray-300 rounded-lg"
          value={updateData.bank_name}
          onChange={(e) => setUpdateData({ ...updateData, bank_name: e.target.value })}
          required
        >
          <option value="" disabled>Select Bank</option>
          {bankList.map((bank) => (
            <option key={bank.id} value={bank.name} data-code={bank.code}>
              {bank.name}
            </option>
          ))}
        </select>
      </div>
          <div className="flex flex-col gap-2 mt-2">
            <label className="block text-gray-700 text-sm font-bold mb-2"htmlFor="account_name">Account Name <span className='t text-red-600'>*</span></label>
            <input 
            className="w-full p-2 border border-gray-300 rounded-lg"  
              type="text"
              id="account_name"
              name="account_name"              
              placeholder='Account Name'
              onChange={(e) => setUpdateData({ ...updateData, account_name: e.target.value })}
              required
            />
          </div>
          <div className="flex flex-col gap-2 mt-2">
          <label htmlFor="account_number" className="block text-gray-700 text-sm font-bold mb-2">Account Number <span className='t text-red-600'>*</span></label>
            <input 
            className="w-full p-2 border border-gray-300 rounded-lg"  
              type="number"
              id="account_number"
              placeholder='Account Number'
              name="account_number"              
              onChange={(e) => setUpdateData({ ...updateData, account_number: e.target.value })}
              required
            />
          </div>          
          <button type="submit" className= "text-white bg-blue-900 w-190 m-2 p-2 rounded-xl">
                Save Updated Details
          </button>
          <button type="submit" onClick={handleDelete} className= "text-white bg-red-500 w-190 m-2 p-2 rounded-xl">
                Delete Account
          </button>
        </form>      
        
      </div>
        </div>
      
      </div>
      {/* <div>
        <h3>Delete Payment</h3>
        <button onClick={handleDelete}>Delete Payment</button>
      </div> */}
    </div>
    </div>
  );
}
//           onChange={(e) => setUpdateData({ ...updateData, account_name: e.target.value })}

// onChange={(e) => setUpdateData({ ...updateData, bank_name: e.target.value })}

export default PaymentUpdateDelete;
