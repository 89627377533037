import React, { useState, useEffect,useContext} from 'react';
import Footer from './footer';
import Header from './Header';
import {motion} from 'framer-motion'
import { MdDelete } from "react-icons/md";
import { useStateValue } from '../Context/StateProvider';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { getError } from '../utils';
function DashDesposit() {
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [show, setShow] = useState(false);
  const [note, setNote] = useState('');
  const [bankName, setBankName] = useState('');
  const navigate = useNavigate();
  const [bankCode, setBankCode] = useState('');
  const [{ allOrder,totalIncome,desposit,user ,allPayment}] = useStateValue();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [bankList, setBankList] = useState([]);
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await fetch('https://api.paystack.co/bank', {
          method: 'GET',
          headers: {
            Authorization: `Bearer YOUR_PAYSTACK_SECRET_KEY`,
          },
        });
        const data = await response.json();
        setBankList(data.data);
      } catch (error) {
        console.error('Error fetching banks:', error);
      }
    };
    fetchBanks();
  }, []);

  const accChecker = desposit ? desposit.filter((obj) => obj?.user_id === userInfo?._id) : [];
  const handleSubmit = async (e) => {
    if (!bankName || !accountNumber  || !accountName) {
      toast.warning('Required fields are missing');
    } else {
      e.preventDefault();       
    try {
      await axios.post(`https://optimistic-investment.onrender.com/api/payStack/save`, {
        bank_name: bankName,
        account_name: accountName,
        account_number: accountNumber,    
        user_id: userInfo._id,       
      });
      toast.success(' Successfull Sent!');
      window.location.reload();
      navigate("/home");       
    } catch (error) {
      const errorMessage = getError(error);
      toast.error(errorMessage);
    }
  }};

  return (
    <div className="relative min-h-screen backdrop-blur-2xl">
    <div>    
    <div className="flex justify-center m py-3 px-5 my-4 bg-white rounded mx-4">
     <h1 className="flex items-center justify-center  text-2xl">Bind Account</h1>      
 </div>
 {accChecker?(
 <div className='w-full pb-3 px-8'>
   <motion.button 
                  whileTap={{ scale: 0.8 }}        
                  transition={{ duration: 0.3}}
                  type='button' 
                  className=' bg-blue-900  text-white w-full  p-1 rounded' 
                  onClick={() =>setShow(!show)}
              >
            + Add Account
              </motion.button>
 </div>
 
 ):(
    <div></div>
 )}
  
 {accChecker.map((accChecker,index)=>(
           <motion.div
          key={accChecker._id}      
          initial={{ opacity: 0, translateX: -50 }}
          animate={{ opacity: 1, translateX: 0 }}
          transition={{ duration: 0.3, delay:  0.3 }}
          className="relative m-2 w-[95vw] min-w-210 p-2 cursor-pointer tra  bg-textColor shadow-md rounded-lg flex items-center" 
        >
         

        <div className="text-base w-full px-2 text-black">              
        <div className='flex text-xl font-medium flex-col '>   
                    <h1 className='w-full text-center text-2xl capitalize'>Account {index +1 }</h1>               
                     <div className='flex flex-col justify-between'>
                        <h3 className='  text-xl font-medium  '>Account Name : {accChecker.account_name}</h3>
                        <h3 className=' text-xl font-medium  text-t'>Bank Name : {accChecker.bank_name}</h3>
                        <h3 className=' text-xl font-medium  text-t'>Account Number : {accChecker.account_number}</h3>
                        {/* <h3 className=' text-xl font-medium  text-t'>{data.account_number/}</h3> */}
                       
                     </div>                      
                   </div> 
                      
                             
              <div
                          className='flex justify-end'                    
                          onClick={() => navigate(`/dashdeposit/${accChecker._id}`)}
                          >                
                      <p 
                      className='- underline text-white m-2 p-2 rounded-xl' >
                          Edit
                      </p>                              
                    </div>                      
                                                   
        </div>      
      </motion.div>
        ))}
          
  {accChecker ?(
    !show?(
    <div></div>
    ):(
  <div>
      
      <div className="flex flex-col w-full mb-20 h-full bg-gray-100">        
        <div className="w-full h-full bg-white rounded-lg shadow-md p-6">
        <div className="flex flex-col w-full mt-5 mb-20 h-full bg-gray-100">
        <h2 className="text-2xl text-center font-bold mb-4">Enter Your Account Details</h2>      
        <form className="mx-3" onSubmit={handleSubmit}>
        <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Bank Name <span  className='t text-red-600'>*</span></label>
        <select
          className="w-full p-2 border border-gray-300 rounded-lg"
          value={bankName}
          onChange={(e) => {
            setBankName(e.target.value);
            setBankCode(e.target.options[e.target.selectedIndex].getAttribute('data-code'));
          }}
          required
        >
          <option value="" disabled>Select Bank</option>
          {bankList.map((bank) => (
            <option key={bank.id} value={bank.name} data-code={bank.code}>
              {bank.name}
            </option>
          ))}
        </select>
      </div>
          <div className="flex flex-col gap-2 mt-2">
            <label className="block text-gray-700 text-sm font-bold mb-2"htmlFor="account_name">Account Name <span className='t text-red-600'>*</span></label>
            <input 
            className="w-full p-2 border border-gray-300 rounded-lg"  
              type="text"
              id="account_name"
              name="account_name"              
              placeholder='Account Name'
              onChange={(e) => setAccountName(e.target.value)}
              required
            />
          </div>
          <div className="flex flex-col gap-2 mt-2">
          <label htmlFor="account_number" className="block text-gray-700 text-sm font-bold mb-2">Account Number <span className='t text-red-600'>*</span></label>
            <input 
            className="w-full p-2 border border-gray-300 rounded-lg"  
              type="number"
              id="account_number"
              placeholder='Account Number'
              name="account_number"              
              onChange={(e) => setAccountNumber(e.target.value)}
              required
            />
          </div>          
          <button type="submit" className= "text-white bg-red-500 w-190 m-2 p-2 rounded-xl">
            Save Bank Details
          </button>
        </form>      
        
      </div>
        </div>
      
      </div>
        </div>      
    )
  ):(
  <div className="flex flex-col w-full mb-20 h-full bg-gray-100">        
        <div className="w-full h-full bg-white rounded-lg shadow-md p-6">
        <div className="flex flex-col w-full mt-5 mb-20 h-full bg-gray-100">
        <h2 className="text-2xl text-center font-bold mb-4">Enter Your Account Details</h2>      
        <form className="mx-3" onSubmit={handleSubmit}>
        <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Bank Name <span  className='t text-red-600'>*</span></label>
        <select
          className="w-full p-2 border border-gray-300 rounded-lg"
          value={bankName}
          onChange={(e) => {
            setBankName(e.target.value);
            setBankCode(e.target.options[e.target.selectedIndex].getAttribute('data-code'));
          }}
          required
        >
          <option value="" disabled>Select Bank</option>
          {bankList.map((bank) => (
            <option key={bank.id} value={bank.name} data-code={bank.code}>
              {bank.name}
            </option>
          ))}
        </select>
      </div>
          <div className="flex flex-col gap-2 mt-2">
            <label className="block text-gray-700 text-sm font-bold mb-2"htmlFor="account_name">Account Name <span className='t text-red-600'>*</span></label>
            <input 
            className="w-full p-2 border border-gray-300 rounded-lg"  
              type="text"
              id="account_name"
              name="account_name"              
              placeholder='Account Name'
              onChange={(e) => setAccountName(e.target.value)}
              required
            />
          </div>
          <div className="flex flex-col gap-2 mt-2">
          <label htmlFor="account_number" className="block text-gray-700 text-sm font-bold mb-2">Account Number <span className='t text-red-600'>*</span></label>
            <input 
            className="w-full p-2 border border-gray-300 rounded-lg"  
              type="number"
              id="account_number"
              placeholder='Account Number'
              name="account_number"              
              onChange={(e) => setAccountNumber(e.target.value)}
              required
            />
          </div>          
          <button type="submit" className= "text-white bg-red-500 w-190 m-2 p-2 rounded-xl">
            Save Bank Details
          </button>
        </form>      
        
      </div>
        </div>
      
      </div>
  )
  }
            
    </div>
    </div>
  );
}

export default DashDesposit;



