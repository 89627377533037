import React, {  useState } from 'react'
import Footer from './footer'
import {motion} from 'framer-motion'

import { useStateValue } from '../Context/StateProvider';
import { useNavigate } from 'react-router-dom';

export const DashboardUserCard = ({data,index}) => {          
    const navigate = useNavigate();
  
    const status= () => {
      if (data.paid === true) {
      return "completed"
      }else if (data.paid=== false ) {
      return "pending"
      } else if (data.paid === false&& data.canceled === true) {
      return "canceled"
      }
    }

    return (
    
              
        <motion.div
          key={data._id}      
          initial={{ opacity: 0, translateX: -50 }}
          animate={{ opacity: 1, translateX: 0 }}
          transition={{ duration: 0.3, delay: index * 0.3 }}
          className="relative m-2 w-[95vw] min-w-210 p-2 cursor-pointer tra  bg-white shadow-md rounded-lg flex items-center" 
        >
        

        <div className="text-base w-full px-2 text-black">              
        <div className='flex text-xl font-medium flex-col '>                  
                     <div className='flex justify-between'>
                        <h3 className='  text-xl font-medium '>{data.name}</h3>
                        <h3 className=' text-xl font-medium  text-t'>{data.amount
}</h3>
                       
                     </div>                      
                   </div>
                    
                  
                    
<div className='flex w-full'>
  <h2 className={`block text-sm ${(data.canceled ? "bg-red-600" : data.isPaid ? "bg-green-600" : "bg-yellow-600")} p-1 rounded font-bold m-2`}>
    {data.canceled ? "Canceled" : data.isPaid ? "Completed" : "Pending"}
  </h2>
</div>




                  
                    
              <div
                          className='flex justify-end'                    
                          onClick={() => navigate(`/dashboard/depositlist/${data._id}`)}
                          >                
                      <p 
                      className='- underline text-textColor m-2 p-2 rounded-xl' >
                          Details
                      </p>                              
                    </div>                                                         
        </div>      
      </motion.div>  
     
    ) 
  }
  
  
  const Paymentlist = () => {
      const  [{allDespPayment} ] = useStateValue()
      const [searchQuery, setSearchQuery] = useState('')
      const filteredProducts = allDespPayment?.filter((product) =>
    product?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  ).reverse();
      console.log(allDespPayment)
    return (
      <div className="w-full p-4 flex items-center justify-center flex-col">
      <div className="flex justify-center mb-3 w-full ">
        <label className="block text-xl mx-2 my-1">Search: </label>
        <input
          className=" p-2 border mr-1  border-gray-300 rounded-lg"
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search name......."
        />

      
      </div>
      <div className="relative w-full py-12 min-h[400px] overflow-x-scroll my-4 flex flex-col items-center justify-start p-4 border-gray-300 rounded-md gap-3">
        <div className="absolute w-full top-4 left-4">
          <p className="text-sm text-black font-sm">
            count :{' '}
            <span className="text-sm font-sm text-textColor">
              {filteredProducts?.length || 0}
            </span>
          </p>
        </div>
        {filteredProducts &&
          filteredProducts.map((data, i) => (
            <DashboardUserCard data={data} index={i} key={data._id} />
          ))}
      </div>
       
    </div>
    )
  }
  export default Paymentlist;
