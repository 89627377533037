import React, { useEffect, useState } from 'react'
import Footer from './footer'
import {  getAllOrder} from '../Api';
import { actionType } from '../Context/reducer';
import {motion} from 'framer-motion'
import moment from "moment";
import { useStateValue } from '../Context/StateProvider';






export const DashboardUserCard = ({data,index}) => {
    
    const createdAt = moment(new Date(data.createdAt)).format("MMM Do YY")

  
    return (
      <motion.div 
      key={index}
      className="relative m-2 w-[95vw] min-w-210 justify-evenly  p-2 cursor-pointer tra  bg-white shadow-md rounded-lg flex items-center" 
      >        
     
        <p className='text-base  text-center text-black'>{data.user_name}</p>
        <p className='text-base  text-center text-black'>{data.invest_price}</p>      
        <p className='text-base  text-center text-black'>{createdAt}</p>        
      </motion.div>      
    ) 
  }
  
  
  const DashOrder = () => {
      const  [{allOrder},dispatch ] = useStateValue()
      const [searchQuery, setSearchQuery] = useState('');
      useEffect(() => {
        if (!allOrder) {
          getAllOrder().then((data) => {
            dispatch({
              type: actionType.SET_ALL_ORDER,
              allOrder: data?.order,
            });        
          });
        }
      }, [allOrder,dispatch]);
    //   const filteredProducts = allOrder?.filter((product) =>
    //   product.user_name.toLowerCase().includes(searchQuery.toLowerCase())
    // );
    return (
      <div className="w-full p-4 flex items-center justify-center flex-col">
      <div className="flex justify-center mb-3 w-full ">
        <label className="block text-xl mx-2 my-1">Search: </label>
        <input
          className=" p-2 border mr-1  border-gray-300 rounded-lg"
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search name......."
        />

      
      </div>
      <div className="relative w-full py-12 min-h[400px] overflow-x-scroll my-4 flex flex-col items-center justify-start p-4 border-gray-300 rounded-md gap-3">
        <div className="absolute w-full top-4 left-4">
          <p className="text-sm text-black font-sm">
            count :{' '}
            <span className="text-sm font-sm text-textColor">
              {allOrder?.length || 0}
            </span>
          </p>
        </div>
        {allOrder &&
          allOrder.map((data, i) => (
            <DashboardUserCard data={data} index={i} key={data._id} />
          ))}
      </div>
      <Footer />
    </div>
    )
  }
  export default DashOrder;